import React, { useEffect, useState, useRef, useCallback, useReducer } from 'react';
import { connect } from 'react-redux';
import { List} from 'immutable';
import {
    selectTechnology,
    operatingConditions,
    disableSizingResultsButton,
    minFlowRate,
    normalFlowRate,
    maxFlowRate,
    fullFlowRate,
    unitsFlowRate,
    minLinePressure,
    normalLinePressure,
    maxLinePressure,
    fullLinePressure,
    flowRateDecimalFactorPref,
    temperatureDecimalFactorPref,
    linePressureDecimalFactorPref,
    unitsLinePressure,
    minTemperature,
    normalTemperature,
    maxTemperature,
    fullTemperature,
    unitsTemperature,
    minAmbient,
    normalAmbient,
    maxAmbient,
    fullAmbient,
    unitsAmbient,
    unitsDensity,
    unitsViscosity,
    unitsVapor,
    customFluidName,
    fluidSource,
    calculateDensityVariation,
    dropdownData,
    minDensity,
    minDensityValue,
    normalDensity,
    normalDensityValue,
    maxDensity,
    maxDensityValue,
    minViscosity,
    minViscosityValue,
    normalViscosity,
    normalViscosityValue,
    maxViscosity,
    maxViscosityValue,
    unitsGasFlowRate,
    minVapor,
    errorMessage,
    translationData,
    transError,
    clearAllField,
    baseReferenceConditions,
    pickFluidsFromDB,
    productFamily,
    processLineSize,
    lineSize,
    toggleHygienic,
    toggleFlanged,
    toggleWafer,
    toggleFlow,
    toggleDensity,
    toggleViscosity,
    toggleReducer,
    toggleDual,
    toggleQuad,
    tooglePressure,
    toggleInLine,
    toggleDisplayAllSensorsNoFilters,
    toggleFlowThroughChamberSlipstream,
    toggleTank,
    unitsPressureAtmospheric,
    pressureAtmospheric,
    unitsSonicVelocity,
    equipmentSelection,
    fluidService,
    toggleHighProcessNoiseSlurry,
    toggleHygienicApplication,
    resetOperatingConditions,
    fluidState,
    conductivityDropdown,
    sonicVelocity,
    sonicVelocityMap,
    sonicVelocityValue,
    linePressMinErrors,
    linePressNormErrors,
    linePressMaxErrors,
    temperatureMinErrors,
    temperatureNormErrors,
    temperatureMaxErrors,
    linePressMinClassList,
    linePressNormClassList,
    linePressMaxClassList,
    temperatureMinClassList,
    temperatureNormClassList,
    temperatureMaxClassList,
    customErrors,
    gasDensity,
    gasPressure,
    gasTemperature,
    gasDensityValue,
    compositionMethod,
    databaseGasses,
    moleArrayRedux,
    selectedDatabaseNaturalGas,
    naturalGasCompositionName,
    selectedComponents, 
    gasCompressibility,
    densitySG,
    densitySGValue,
    maxPressureDrop,
    wettedMaterial,
    linePressRow,
    tempRowF,
    unitsDensityHeading, 
    molecularWeight,
    specificGravity,
    nonNewtonianLiquid,
    revertNonNewtonianCheckboxFlag,
    scheduleDropdown,
    setCalculationFlag,
    minVaporValue,
    unitsGasDensity,
    unitsGasPressure,
    unitsGasTemperature,
    pickGasFromDB
} from '../../redux/actions';
import OperatingConditions from './OperatingConditions';
import useConvertForUom from '../../UtilityFuntions/useConvertForUom';
import ProcessVariables from './ProcessVariables';
import FluidProperties from './FluidProperties';

import Validation from './Validation';
import { getUnitsKeyValue } from '../../UtilityFuntions/getUnitsKeyValue';
import axios from 'axios';
import CalculationCall from './CalculationCall';

const OperatingConditionsData = (props) => {
    const [calculationResults, setCalculationResults] = useState({
        errors: []
    })
    const [calculationsCall, setCalculationsCall] = useState(false);
    const [displayApiErrors, setDisplayApiErrors] = useState(false);
    const [initialData, setInitialData] = useState([]);
    const [searchedData, setSearchedData] = useState();
    const [resizingFlag, setResizingFlag] = useState(false)
    const userUpdate = useRef('');
    const [state, dispatch] = useReducer(
        ProcessVariables
      );

    const allowCalculationResults = useRef(true);
    // the four initial row objects
    const flowRow =  {
        lable: 'XXSPECIFY_MMI_MC_LBL_FR',
        inputs: [
        {
            id: 'flow-rate-min',
            className: props.flow_rate_min_class_list,
            errorMessage: props.flow_rate_min_errors,
            value: props.min_flow_rate,
        },
        {
            id: 'flow-rate-normal',
            className: props.flow_rate_norm_class_list,
            errorMessage: props.flow_rate_norm_errors,
            value: props.normal_flow_rate,
        },
        {
            id: 'flow-rate-max',
            className: props.flow_rate_max_class_list,
            errorMessage: props.flow_rate_max_errors,
            value: props.max_flow_rate,
        },
        {
            id: 'flow-rate-full',
            className: props.flow_rate_full_class_list,
            errorMessage: props.flow_rate_full_errors,
            value: props.full_flow_rate,
        },
        ],
        units: {
            dropdown_array:props.fluid_state? props.fluid_state.toLowerCase()==='gas' ?props.units_data.flowRateGas: props.flow_rate_dropdown_array:[],
            id: 'flow-rate-units',
            unit: 'flow-rate',
            text: props.fluid_state?props.fluid_state.toLowerCase()==='gas' ? props.units_gas_flow_rate : props.units_flow_rate:props.units_flow_rate
        }
    };
    
    const lineRow = {
        lable: 'XXSPECIFY_MMI_MC_LBL_LPRES',
        inputs: [
          {
            id: 'line-press-min',
            className: props.line_press_min_class_list,
            errorMessage: props.line_press_min_errors,
            value: props.min_line_pressure,
          },
          {
            id: 'line-press-normal',
            className: props.line_press_norm_class_list,
            errorMessage: props.line_press_norm_errors,
            value: props.normal_line_pressure,
          },
          {
            id: 'line-press-max',
            className: props.line_press_max_class_list,
            errorMessage: props.line_press_max_errors,
            value: props.max_line_pressure,
          },
          {
            id: 'line-press-full',
            className: props.line_press_full_class_list,
            errorMessage: props.line_press_full_errors,
            value: props.full_line_pressure,
          },
        ],
        units: {
          dropdown_array: props.line_press_dropdown_array,
          id: 'line-press-units',
          unit: 'line-pressure',
          text: props.units_line_pressure
        }
    };

    const tempRow = {
        lable: 'XXSPECIFY_MMI_MC_LBL_TEMP',
        inputs: [
        {
            id: 'temp-min',
            className: props.temperature_min_class_list,
            errorMessage: props.temperature_min_errors,
            value: props.min_temperature,
        },
        {
            id: 'temp-normal',
            className: props.temperature_norm_class_list,
            errorMessage: props.temperature_norm_errors,
            value: props.normal_temperature,
        },
        {
            id: 'temp-max',
            className: props.temperature_max_class_list,
            errorMessage: props.temperature_max_errors,
            value: props.max_temperature,
        },
        {
            id: 'temp-full',
            className: props.temperature_full_class_list,
            errorMessage: props.temperature_full_errors,
            value: props.full_temperature,
        },
        ],
        units: {
        dropdown_array: props.temperature_dropdown_array,
        id: 'temp-min-units',
        unit: 'temperature',
        text: props.units_temperature
        },
    };

    const ambientRow = {
        lable: 'XXSPECIFY_MMI_MC_TXT_AMBTEM',
        inputs: [
        {
            id: 'ambient-min',
            className: props.ambient_temperature_min_class_list,
            errorMessage: props.ambient_temperature_min_errors,
            value: props.min_ambient,
        },
        {
            id: 'ambient-normal',
            className: props.ambient_temperature_norm_class_list,
            errorMessage: props.ambient_temperature_norm_errors,
            value: props.normal_ambient,
        },
        {
            id: 'ambient-max',
            className: props.ambient_temperature_max_class_list,
            errorMessage: props.ambient_temperature_max_errors,
            value: props.max_ambient,
        },
        {
            id: 'ambient-full',
            className: props.ambient_temperature_full_class_list,
            errorMessage: props.ambient_temperature_full_errors,
            value: props.full_ambient,
        },
        ],
        units: {
        dropdown_array: props.temperature_dropdown_array,
        id: 'ambient-units',
        unit: 'ambient-temp',
        text: props.units_ambient
        }
    };

    // this builds the initial tableData from an array of the four objects that make each line
    const operatingData = [ flowRow, lineRow, tempRow, ambientRow];
   
    // this sets the prop that will be passed down to the children 
    let tableData = operatingData;

    // This section stores the searched data for comparison and history
    // const searchedDataList = List([searchedData]);

    // this sets the prop that will be passed down to the children 
    useEffect(() => {
        if (Object.keys(props.reset_operating_conditions).length = 0) {
            tableData = initialData
        } 
    }, [props.reset_operating_conditions])


    // This section stores the searched data for comparison and history
    
    useEffect(() => {
        if (props.was_clicked === true) {
            setSearchedData(tableData)

        }
    }, [props.was_clicked]);
    
    // reset tableData 
    useEffect(() => {
        const allowCalcResults = () => {
            if (!allowCalculationResults.current) {    
                allowCalculationResults.current = true;    
            }
        }
        const clearData = () => {
            if (props.clear_all_field) {
                allowCalculationResults.current = false;
                dispatch({ type:'CLEAR_PROC_VARIABLES' })
                setCalculationResults({
                    errors: []
                });
                setDisplayApiErrors(false);
                setInterval(() => {
                    allowCalcResults()
                }, 4500)
            }
        }
        clearData()
    }, [props.clear_all_field])

   
    // callback from child (OperatingConditions)
    const setCalcCall = useCallback((bool) => {
        if (!props.clear_all_field && allowCalculationResults.current) {
            setCalculationsCall(bool)
        }
        setDisplayApiErrors(false)
    }, []);

    // callback from child (Validation)
    const setApiErrors = useCallback((bool) => {
        if (setApiErrors) {
            setDisplayApiErrors(bool)
        }
    }, []);


    // callback from child (Fluid Properties)
    const userEntered = useCallback((callbackUpdate) => {
        let test = callbackUpdate.split('ID');
        let userEnteredCurrent = userUpdate.current;
        if (test[1] === 'min-density' && userEnteredCurrent === 'minDensity' ) {
            props.minDensity('');
            callbackUpdate = test[0]
        }else if (test[1] === 'normal-density' && userEnteredCurrent === 'normalDensity'){
            props.normalDensity('');
            callbackUpdate = test[0]
        }else if (test[1] === 'max-density' && userEnteredCurrent === 'maxDensity'){
            props.maxDensity('');
            callbackUpdate = test[0]
        } else if ((test[1] === 'gas-density' && userEnteredCurrent === 'gasDensity')) {
            callbackUpdate = test[0]
        } else if (test.length >= 2){
            callbackUpdate = userUpdate.current;
        }
        //else if((test[1] === 'compressibility' && userEnteredCurrent === 'compressibilitySG')){
          //  callbackUpdate = test[0]
        //}
        userUpdate.current = callbackUpdate;
        //setCalcCall(true)
    }, []);

    //disables the Sizing Results button when the calculations call is pending
    useEffect(() => {
        // if((props.fluid_state.toLowerCase()==='liquid'||props.fluid_state.toLowerCase()==='slurry')&&props.fluid_source.toLowerCase()==='custom'){//
        //     props.disableSizingResultsButton(props.convert_disable_button)
        //     //props.disableSizingResultsButton(false);
        // }
         if (props.fluid_source.toLowerCase()==='database'&&calculationResults.errors.length>0&&props.density_norm==='') {
            props.disableSizingResultsButton(true);
        }
        // else if(props.fluid_state.toLowerCase()==='gas' && props.fluid_source.toLowerCase()==='custom'){
        //     props.disableSizingResultsButton(props.convert_disable_button)
        // }
        else {
            props.disableSizingResultsButton(false);
        }
        
    }, [calculationResults,calculationsCall])

    useEffect(() => {
        setCalculationsCall(props.calculation_call);
    }, [props.calculation_call])

    //Custom hook for Calculation API
    let Result = CalculationCall(props, calculationsCall, userUpdate.current);

  //Setting Calculation Results from the Calculation API response
    useEffect(() => {
        if(Result && allowCalculationResults.current && Result.errors){
            setCalculationResults(Result)
        } else {
            let result = {
                errors: Result.errors ? Result.errors : [],
                Result
            }
            setCalculationResults(result);        
        }
    setCalculationsCall(false);
    }, [Result]);


// Loads saved sizings and populates into table data
    useEffect(() => {
        if (Object.keys(props.get_sizing_data).length > 0) {
            setResizingFlag(true)
          let data = props.get_sizing_data[0];
          const fetchData = async () => {
          parseFloat(data.minpres).toFixed(props.temperature_decimal)
          await props.minFlowRate(data.minflow === null ? '' :   parseFloat(data.minflow).toFixed(props.flow_rate_decimal));
          await props.normalFlowRate(data.opflow === null ? '' :   parseFloat(data.opflow).toFixed(props.flow_rate_decimal));
          await props.maxFlowRate(data.maxflow === null ? '' :   parseFloat(data.maxflow).toFixed(props.flow_rate_decimal));
          await props.minLinePressure(data.minpres === null ? '' : parseFloat(data.minpres).toFixed(props.line_pressure_decimal));
          await props.normalLinePressure(data.oppres === null ? '' : parseFloat(data.oppres).toFixed(props.line_pressure_decimal));
          await props.maxLinePressure(data.maxpres === null ? '' : parseFloat(data.maxpres).toFixed(props.line_pressure_decimal));
          await props.minTemperature(data.mintemp === null ? '' :parseFloat(data.mintemp).toFixed(props.temperature_decimal));
          await props.normalTemperature(data.optemp === null ? '' :parseFloat(data.optemp).toFixed(props.temperature_decimal));
          await props.maxTemperature(data.maxtemp === null ? '' :parseFloat(data.maxtemp).toFixed(props.temperature_decimal));
          await props.fullFlowRate(data.desflow === null ? '' :   parseFloat(data.desflow).toFixed(props.flow_rate_decimal));
          await props.fullLinePressure(data.despres === null ? '' : parseFloat(data.despres).toFixed(props.line_pressure_decimal));
          await props.fullTemperature(data.destemp === null ? '' :parseFloat(data.destemp).toFixed(props.temperature_decimal));
          await props.fullAmbient(
            data.design_ambient_temp === null ? '' : parseFloat(data.design_ambient_temp).toFixed(props.temperature_decimal)
          );
          props.minAmbient(
            data.min_ambient_temp === null ? '' : parseFloat(data.min_ambient_temp).toFixed(props.temperature_decimal)
          );
          props.normalAmbient(
            data.opt_ambient_temp === null ? '' : parseFloat(data.opt_ambient_temp).toFixed(props.temperature_decimal)
          );
          props.maxAmbient(
            data.max_ambient_temp === null ? '' : parseFloat(data.max_ambient_temp).toFixed(props.temperature_decimal)
          );
          props.calculateDensityVariation(
            data.four_corner_flag === 'Y' ? true : false
          );
          }
          fetchData();
          setCalculationsCall(false);
          tableData = searchedData
        }
        setTimeout(() => {
            setResizingFlag(false)
        }, 3000);
      }, [props.get_sizing_data]);

    // Determining when to set calc call to true
    // clears density if fluid source changes to custom when fluid state is gas
    useEffect(() => {
        if (props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && (!Object.keys(props.get_sizing_data).length > 0)&&!props.fluid_selection_flag) {
            allowCalculationResults.current = false
            props.normalDensity('');
            props.minDensity('');
            props.maxDensity('');
            props.gasDensity('');
            props.minViscosity('');
            props.normalViscosity('');
            props.maxViscosity('');
            setCalcCall(false);
            setInterval(() => {
                allowCalculationResults.current = true
            }, 3000)
        }
    }, [props.fluid_source])



      useEffect(() => {
        if (Object.keys(props.get_sizing_data).length > 0) {
          setCalculationsCall(false);
        } else if (
          props.normal_flow_rate !== '' &&
          props.normal_line_pressure !== '' &&
          props.normal_temperature !== '' &&
          props.normal_ambient !== '' &&
          !props.clear_all_field
        ) {
          setCalculationsCall(true);
          setDisplayApiErrors(false)
        }
      }, [
        props.fluid_state,
        props.fluid_source,
        props.pick_fluids_from_db,
        props.clear_all_field,
        props.normal_flow_rate,
        props.get_sizing_data,
      ]);

      
      

   // setting the values which will be passed to Fluid Properties and calculated
   useEffect(() => {
   
       let switchGasMinimimViscosity = props.fluid_source.toLowerCase() === 'custom' ? calculationResults.viscosity_min_custom : calculationResults.viscosity_min;
       let switchGasNormalViscosity = props.fluid_source.toLowerCase() === 'custom' ? calculationResults.viscosity_norm_custom :calculationResults.viscosity_norm;
       let switchGasMaximumViscosity = props.fluid_source.toLowerCase() === 'custom' ? calculationResults.viscosity_max_custom : calculationResults.viscosity_max;
       if (allowCalculationResults.current&&props.fluid_source.toLowerCase()!=='custom') {
        if(!props.sizing_results_clicked){
        props.maxPressureDrop(
            parseFloat(calculationResults.max_pressure_drop)
            ?parseFloat(calculationResults.max_pressure_drop).toFixed(props.all_other_decimal)
            : '');
        }
        
          // if (userUpdate.current !== 'normalDensity') {
            props.normalDensity(
                parseFloat(calculationResults.density_norm)
                    ? parseFloat(calculationResults.density_norm).toString()
                    : ''
                );

            props.normalDensityValue(
                parseFloat(calculationResults.density_norm)
                    ?parseFloat(calculationResults.density_norm).toString() : ''
            );
            
            props.linePressRow(
                calculationResults.press_map_psig !== undefined?
                Object.values(calculationResults.press_map_psig) :''
            )

            props.tempRowF(
                calculationResults.temp_map_k !== undefined?
                Object.values(calculationResults.temp_map_k) :''
            )
            
            //}
            if(props.steam_pressure_temp === 'Based on Saturated Temperature'){
                
                props.normalLinePressure(
                    parseFloat(calculationResults.line_pressure_norm)
                    ? parseFloat(calculationResults.line_pressure_norm).toFixed(
                        props.line_pressure_decimal
                    )
                    : ''
                )
            }
            if(props.steam_pressure_temp === 'Based on Saturated Pressure'){
                props.normalTemperature(
                    parseFloat(calculationResults.temperature_norm)
                    ? parseFloat(calculationResults.temperature_norm).toFixed(
                        props.temperature_decimal
                    )
                    : ''
                )
            }

            if (userUpdate.current !== 'minDensity' ) {
                props.minDensity(
                parseFloat(calculationResults.density_min)
                    ? parseFloat(calculationResults.density_min).toString()
                    : ''
                );
                props.minDensityValue(
                    parseFloat(calculationResults.density_min) 
                    ?parseFloat(calculationResults.density_min).toString():''
                )
            }
           // if (userUpdate.current !== 'maxDensity') {                
                props.maxDensity(
                parseFloat(calculationResults.density_max)
                    ? parseFloat(calculationResults.density_max).toString()
                    : ''
                );   
                
                props.maxDensityValue(
                    parseFloat(calculationResults.density_max)
                    ? parseFloat(calculationResults.density_max).toString():''
                )
           // }
           
           
            props.minViscosity(
            parseFloat(switchGasMinimimViscosity)
                ? parseFloat(switchGasMinimimViscosity).toString()
                : ''
            );

            props.minViscosityValue(
                parseFloat(switchGasMinimimViscosity)
                ? parseFloat(switchGasMinimimViscosity).toString():''
            );


            props.normalViscosity(
                parseFloat(switchGasNormalViscosity)
                    ? parseFloat(switchGasNormalViscosity).toString()
                    : ''
                );
            
            props.normalViscosityValue(
                parseFloat(switchGasNormalViscosity)
                ? parseFloat(switchGasNormalViscosity).toString():''
            )


            props.maxViscosity(
            parseFloat(switchGasMaximumViscosity)
                ? parseFloat(switchGasMaximumViscosity).toString()
                : ''
            );

            props.maxViscosityValue(
                parseFloat(switchGasMaximumViscosity)
                ? parseFloat(switchGasMaximumViscosity).toString():''
            )

            props.minVapor(
            parseFloat(calculationResults.vapor_pressure)
                ? parseFloat(calculationResults.vapor_pressure).toString()
                : ''
            );

            props.minVaporValue(
                parseFloat(calculationResults.vapor_pressure)
                    ? parseFloat(calculationResults.vapor_pressure).toString()
                    : ''
                );

            props.sonicVelocity(
            parseFloat(calculationResults.sonic_velocity)
                ? parseFloat(calculationResults.sonic_velocity).toString()
                : ''
            );

            props.sonicVelocityMap(
            calculationResults.sonic_velocity_map
                ? calculationResults.sonic_velocity_map
                : {}
            );

            props.sonicVelocityValue(
                parseFloat(calculationResults.sonic_velocity)
                    ? parseFloat(calculationResults.sonic_velocity).toString()
                    : ''
                );
    
                

            if(!Object.keys(props.get_sizing_data).length > 0&&props.fluid_state.toLowerCase()!=='gas' && props.fluid_source.toLowerCase() !=='custom' && 
            props.units_density_heading !=='Specific Gravity'&& props.units_density_heading !=='Molecular Weight'){

            /*
                props.normalLinePressure(
                    parseFloat(calculationResults.line_pressure_norm)
                    ? parseFloat(calculationResults.line_pressure_norm).toFixed(
                        props.line_pressure_decimal
                    )
                    : ''
                )
                
                props.normalTemperature(
                    parseFloat(calculationResults.temperature_norm)
                    ? parseFloat(calculationResults.temperature_norm).toFixed(
                        props.temperature_decimal
                    )
                    : ''
                )
            */
                props.normalViscosity(
                    parseFloat(switchGasNormalViscosity)
                        ? parseFloat(switchGasNormalViscosity).toString()
                        : ''
                    );
                
                props.normalViscosityValue(
                    parseFloat(switchGasNormalViscosity)
                    ? parseFloat(switchGasNormalViscosity).toString():''
                )
                
            }
            
            if(props.fluid_state.toLowerCase()==='gas' && props.fluid_source.toLowerCase() ==='database'||props.fluid_source.toLowerCase() ==='natural gas'){
                props.normalViscosity(
                    parseFloat(switchGasNormalViscosity)
                        ? parseFloat(switchGasNormalViscosity).toString()
                        : ''
                    );

                props.normalViscosityValue(
                    parseFloat(switchGasNormalViscosity)
                    ? parseFloat(switchGasNormalViscosity).toString():''
                )
            }

            
            if(props.units_density_heading ==='Density'){
                props.gasCompressibility(
                    parseFloat(calculationResults.compressibility)
                    ? parseFloat(calculationResults.compressibility).toFixed(
                        props.all_other_decimal
                    ): ''
                )
            }

            if(props.fluid_state.toLowerCase()=='gas' && props.fluid_source.toLowerCase() =='custom' && 
            props.units_density_heading =='Density'){
                props.densitySG(
                    parseFloat(calculationResults.density) ?
                    parseFloat(calculationResults.density).toString():''
                  )
            }else if(props.fluid_state.toLowerCase()=='gas' && props.fluid_source.toLowerCase() =='custom' && 
            props.units_density_heading =='Specific Gravity' || props.units_density_heading =='Molecular Weight'){
                if(calculationResults.Result){
                    props.densitySG(
                        parseFloat( calculationResults.Result.density) ?
                        parseFloat(calculationResults.Result.density).toString():''
                      )
                    props.densitySGValue(
                        parseFloat( calculationResults.Result.density)
                        ? parseFloat( calculationResults.Result.density).toString():''
                      )
                      
                      props.gasDensity(
                        parseFloat(calculationResults.Result.ref_density)
                            ? parseFloat(calculationResults.Result.ref_density).toFixed(9)
                            : ''
                        );   
                        props.gasDensityValue(
                            parseFloat(calculationResults.Result.ref_density)
                            ?parseFloat(calculationResults.Result.ref_density).toString():''
                        )
                }
                

            }
            

            if (userUpdate.current === '' || userUpdate.current !== 'baseRefDensity' || userUpdate.current === 'CLEAR') { 
                //conditions to set value in density field in gas reference condition
                if(props.fluid_state.toLowerCase() === 'gas' && (props.fluid_source.toLowerCase() ==='database' || props.fluid_source.toLowerCase() ==='natural gas')){

                    props.gasDensity(
                        parseFloat(calculationResults.ref_density)
                            ? parseFloat(calculationResults.ref_density).toFixed(9)
                            : ''
                        );
                    props.gasDensityValue(
                        parseFloat(calculationResults.ref_density)
                        ? parseFloat(calculationResults.ref_density).toString()
                        : ''
                    )
                }
                
            }

       }else if(allowCalculationResults.current&&props.fluid_source.toLowerCase()==='custom'){
       /*
        if (userUpdate.current !== 'minDensity' && userUpdate.current !== 'maxDensity') {
            props.minDensity(
                parseFloat(calculationResults.density_min)
                ? parseFloat(calculationResults.density_min).toFixed(props.all_other_decimal)
                : ''
            );
            props.minDensityValue(
                parseFloat(calculationResults.density_min) 
                ?parseFloat(calculationResults.density_min).toString():''
            )

            props.maxDensity(
                parseFloat(calculationResults.density_max)
                    ? parseFloat(calculationResults.density_max).toFixed(props.all_other_decimal)
                    : ''
                );  
            props.maxDensityValue(
                parseFloat(calculationResults.density_max)
                ? parseFloat(calculationResults.density_max):''
            )
            
        }
       */
      if(Object.keys(calculationResults).length>0){
        props.tempRowF(
            calculationResults.temp_map_k!==undefined?
            Object.values(calculationResults.temp_map_k):''
        )
        
      }
      if(calculationResults.Result){
        props.tempRowF(
            calculationResults.Result?
            Object.values(calculationResults.Result.temp_map_k) :''
        )
      }
      props.linePressRow(
        calculationResults.Result?
        calculationResults.Result.press_map_psig !== undefined?
        Object.values(calculationResults.Result.press_map_psig) :'':''
    )
    
        if((props.fluid_state.toLowerCase()=='slurry' || props.fluid_state.toLowerCase()=='liquid') && props.fluid_source.toLowerCase() =='custom'){      
            if(calculationResults.Result){
                if(!props.sizing_results_clicked){
                props.maxPressureDrop(
                    parseFloat(calculationResults.Result.max_pressure_drop)
                    ?parseFloat(calculationResults.Result.max_pressure_drop).toFixed(props.all_other_decimal)
                    : '');
                }
            }
            
            if(calculationResults.Result && props.units_density_heading ==='Specific Gravity'){
                props.normalDensity(
                    parseFloat(calculationResults.Result.density_norm)
                        ? parseFloat(calculationResults.Result.density_norm).toFixed(props.all_other_decimal)
                        : ''
                );
            }
            

        }
        
        
        
        if(props.fluid_state.toLowerCase()!=='gas' && props.fluid_source.toLowerCase() !=='custom' && 
        props.units_density_heading !=='Specific Gravity'&& props.units_density_heading !=='Molecular Weight'){
            props.normalLinePressure(
                parseFloat(calculationResults.line_pressure_norm)
                ? parseFloat(calculationResults.line_pressure_norm).toFixed(
                    props.line_pressure_decimal
                )
                : ''
            )
            
            props.normalTemperature(
                parseFloat(calculationResults.temperature_norm)
                ? parseFloat(calculationResults.temperature_norm).toFixed(
                    props.temperature_decimal
                )
                : ''
            )
            
            props.normalViscosity(
                parseFloat(switchGasNormalViscosity)
                    ? parseFloat(switchGasNormalViscosity).toString()
                    : ''
                );
                

        }  

        if(props.units_density_heading ==='Density'){
            props.gasCompressibility(
                parseFloat(calculationResults.compressibility)
                ? parseFloat(calculationResults.compressibility).toFixed(
                    props.all_other_decimal
                ): ''
            )

        }

        if(props.fluid_state.toLowerCase()=='gas' && props.fluid_source.toLowerCase() =='custom' && 
        props.units_density_heading =='Density'){
            props.densitySG(
                parseFloat(calculationResults.density) ?
                parseFloat(calculationResults.density).toString():''
              )

              props.densitySGValue(
               parseFloat(calculationResults.density) ?
                parseFloat(calculationResults.density).toString():''
              )

              props.sonicVelocityMap(
                calculationResults.sonic_velocity_map
                   ? calculationResults.sonic_velocity_map
                   : {}
               );
            
            if(!props.sizing_results_clicked){
            props.maxPressureDrop(
            parseFloat(calculationResults.max_pressure_drop)
            ?parseFloat(calculationResults.max_pressure_drop).toFixed(props.all_other_decimal)
            : '');
            }

            props.linePressRow(
                calculationResults?
                calculationResults.press_map_psig !== undefined?
                Object.values(calculationResults.press_map_psig) :'':''
            )
            
        }else if(props.fluid_state.toLowerCase()=='gas' && props.fluid_source.toLowerCase() =='custom' && 
        props.units_density_heading =='Specific Gravity' || props.units_density_heading =='Molecular Weight'){
            if(calculationResults.Result){
                props.densitySG(
                    parseFloat( calculationResults.Result.density) ?
                    parseFloat(calculationResults.Result.density).toString():''
                  )
                props.densitySGValue(
                    parseFloat( calculationResults.Result.density)
                    ? parseFloat( calculationResults.Result.density).toString():''
                  )
                  
                  props.gasDensity(
                    parseFloat(calculationResults.Result.ref_density)
                        ? parseFloat(calculationResults.Result.ref_density).toFixed(9)
                        : ''
                    );   
                    props.gasDensityValue(
                        parseFloat(calculationResults.Result.ref_density)
                        ?parseFloat(calculationResults.Result.ref_density).toString():''
                    )
                    props.sonicVelocityMap(
                        calculationResults.Result.sonic_velocity_map
                            ? calculationResults.Result.sonic_velocity_map
                            : {}
                    );

                    props.sonicVelocity(
                        parseFloat(calculationResults.Result.sonic_velocity)
                            ? parseFloat(calculationResults.Result.sonic_velocity).toString()
                            : ''
                    );

                    if(!props.sizing_results_clicked){
                    props.maxPressureDrop(
                        parseFloat(calculationResults.Result.max_pressure_drop)
                        ?parseFloat(calculationResults.Result.max_pressure_drop).toFixed(props.all_other_decimal)
                        : '');
                    }


                }
            

        }



       }
       
       setCalcCall(false)
       props.setCalculationFlag(false);
    },[calculationResults]);

    // this useEffect allows the user to discard changes to the search query
    // this WILL be done through the ProcessVariables redux store
    useEffect(() => {
        if (Object.keys(props.reset_operating_conditions).length > 0) {
            let reset = props.reset_operating_conditions.diff;
            for (const element of reset) {
            switch (element) {
                case 'select_technology':
                    props.selectTechnology(props.reset_operating_conditions.Fields.select_technology);
                    break;
                case 'min_flow_rate':
                    setTimeout(() => {
                        props.minFlowRate(props.reset_operating_conditions.Fields.min_flow_rate);    
                    }, 300);
                    break;
                case 'normal_flow_rate':
                    setTimeout(() => {
                        props.normalFlowRate(props.reset_operating_conditions.Fields.normal_flow_rate);    
                    }, 300);
                    break;
                case 'max_flow_rate':
                    setTimeout(() => {
                        props.maxFlowRate(props.reset_operating_conditions.Fields.max_flow_rate);    
                    }, 300);
                    break;
                case 'full_flow_rate':
                    props.fullFlowRate(props.reset_operating_conditions.Fields.full_flow_rate);
                    break;
                case 'fluid_state':
                    props.fluidState(props.reset_operating_conditions.Fields.fluid_state);
                    break;
                case 'fluid_source':
                    props.fluidSource(props.reset_operating_conditions.Fields.fluid_source)
                    break;
                case 'process_line_size':
                    props.processLineSize(props.reset_operating_conditions.Fields.process_line_size);
                    break;
                case 'non_newtonian_liquid':
                    props.nonNewtonianLiquid(props.reset_operating_conditions.Fields.non_newtonian_liquid)
                    props.revertNonNewtonianCheckboxFlag(true)
                    break;
                case 'calculate_density_variation':
                    props.calculateDensityVariation(props.reset_operating_conditions.Fields.calculate_density_variation)
                    break;
                case 'line_size':
                    setTimeout(() => {
                        props.lineSize(props.reset_operating_conditions.Fields.line_size);    
                    }, 300);
                    break;
                case 'min_viscosity':
                    setTimeout(() => {
                        props.minViscosity(props.reset_operating_conditions.Fields.min_viscosity);    
                    }, 300);
                    break;
                case 'normal_viscosity':
                    setTimeout(() => {
                        props.normalViscosity(props.reset_operating_conditions.Fields.normal_viscosity);    
                    }, 300);
                    break;
                case 'max_viscosity':
                    setTimeout(() => {
                        props.maxViscosity(props.reset_operating_conditions.Fields.max_viscosity);
                    }, 300);
                case 'min_line_pressure':
                    props.minLinePressure(props.reset_operating_conditions.Fields.min_line_pressure);
                    break;
                case 'normal_line_pressure':
                    props.normalLinePressure(props.reset_operating_conditions.Fields.normal_line_pressure);
                    break;
                case 'max_line_pressure':
                    props.maxLinePressure(props.reset_operating_conditions.Fields.max_line_pressure);
                    break;
                case 'equipment_selection':
                    props.equipmentSelection(props.reset_operating_conditions.Fields.equipment_selection);
                    break;
                case 'full_line_pressure':
                    props.fullLinePressure(props.reset_operating_conditions.Fields.full_line_pressure);
                    break;
                case 'min_temperature':
                    props.minTemperature(props.reset_operating_conditions.Fields.min_temperature);
                    break;
                case 'normal_temperature':
                    props.normalTemperature(props.reset_operating_conditions.Fields.normal_temperature);
                    break;
                case 'max_temperature':
                    props.maxTemperature(props.reset_operating_conditions.Fields.max_temperature);
                    break;
                case 'full_temperature':
                    props.fullTemperature(props.reset_operating_conditions.Fields.full_temperature);
                    break;
                case 'min_ambient':
                    props.minAmbient(props.reset_operating_conditions.Fields.min_ambient);
                    break;
                case 'normal_ambient':
                    props.normalAmbient(props.reset_operating_conditions.Fields.normal_ambient);
                    break;
                case 'max_ambient':
                    props.maxAmbient(props.reset_operating_conditions.Fields.max_ambient);
                    break;
                case 'full_ambient':
                    props.fullAmbient(props.reset_operating_conditions.Fields.full_ambient);
                    break;
                case 'min_density':
                    setTimeout(() => {
                        props.minDensity(props.reset_operating_conditions.Fields.min_density);    
                    }, 300);
                    break;
                case 'normal_density':
                    setTimeout(() => {
                        props.normalDensity(props.reset_operating_conditions.Fields.normal_density);    
                    }, 300);
                    break;
                case 'max_density':
                    setTimeout(() => {
                        props.maxDensity(props.reset_operating_conditions.Fields.max_density);    
                    }, 300);
                    
                    break;
                case 'compressibility':
                    setTimeout(() => {
                        props.gasCompressibility(props.reset_operating_conditions.Fields.compressibility)
                    }, 300);
                    break;
                case 'gas_density':
                    setTimeout(() => {
                        props.gasDensity(props.reset_operating_conditions.Fields.gas_density)
                    }, 300);
                    break;
                case 'gas_pressure':
                    setTimeout(() => {
                        props.gasPressure(props.reset_operating_conditions.Fields.gas_pressure)
                    }, 300);
                    break;
                case 'gas_temperature':
                    setTimeout(() => {
                        props.gasTemperature(props.reset_operating_conditions.Fields.gas_temperature)
                    }, 300);
                    break;
                case 'density_specific_gravity':
                    setTimeout(() => {
                        props.densitySG(props.reset_operating_conditions.Fields.density_specific_gravity)
                    }, 300);
                    break;
                case 'flow':
                    props.toggleFlow(props.reset_operating_conditions.Fields.flow);
                    break;                        
                case 'density':
                    props.toggleDensity(props.reset_operating_conditions.Fields.density);
                    break;
                case 'viscosity':
                    props.toggleViscosity(props.reset_operating_conditions.Fields.viscosity);
                    break;
                case 'flanged':
                    props.toggleFlanged(props.reset_operating_conditions.Fields.flanged);
                    break;                        
                case 'wafer':
                    props.toggleWafer(props.reset_operating_conditions.Fields.wafer);
                    break;                        
                case 'reducer':
                    props.toggleReducer(props.reset_operating_conditions.Fields.reducer);
                    break;
                case 'dual':
                    props.toggleDual(props.reset_operating_conditions.Fields.dual);
                    break;                        
                case 'quad':
                    props.toggleQuad(props.reset_operating_conditions.Fields.quad);
                    break;
                case 'pressure':
                    props.tooglePressure(props.reset_operating_conditions.Fields.pressure)
                    break;
                case 'in_line':
                    props.toggleInLine(props.reset_operating_conditions.Fields.in_line);
                    break;                     
                case 'hygienic':
                    props.toggleHygienic(props.reset_operating_conditions.Fields.hygienic);
                    break;                              
                case 'display_all_sensors_no_filters':
                    props.toggleDisplayAllSensorsNoFilters(props.reset_operating_conditions.Fields.display_all_sensors_no_filters);
                    break;
                case 'flow_through_chamber_slipstream':
                    props.toggleFlowThroughChamberSlipstream(props.reset_operating_conditions.Fields.flow_through_chamber_slipstream);
                    break;
                case 'pick_fluids_from_db':
                    props.pickFluidsFromDB(props.reset_operating_conditions.Fields.pick_fluids_from_db);
                    break;                        
                case 'product_family':
                    props.productFamily(props.reset_operating_conditions.Fields.product_family);
                    break;
                case 'wetted_material':
                    props.wettedMaterial(props.reset_operating_conditions.Fields.wetted_material)
                    break;
                case 'tank':
                    props.toggleTank(props.reset_operating_conditions.Fields.tank);
                    break;
                case 'conductivity':
                    props.conductivityDropdown(props.reset_operating_conditions.Fields.conductivity);
                    break;
                case 'fluid_service':
                    props.fluidService(props.reset_operating_conditions.Fields.fluid_service);
                    break;                        
                case 'high_process_noise_slurry':
                    props.toggleHighProcessNoiseSlurry(props.reset_operating_conditions.Fields.high_process_noise_slurry);
                    break;   
                case 'hygienic_application':
                    props.toggleHygienicApplication(props.reset_operating_conditions.Fields.hygienic_application);
                    break;           
                case 'units_flow_rate':
                    props.unitsFlowRate(props.reset_operating_conditions.Fields.units_flow_rate);
                    break;
                case 'units_gas_flow_rate':
                    props.unitsGasFlowRate(props.reset_operating_conditions.Fields.units_gas_flow_rate);
                    break;          
                case 'units_line_pressure':
                    props.unitsLinePressure(props.reset_operating_conditions.Fields.units_line_pressure);
                    break;
                case 'units_ambient':
                    props.unitsAmbient(props.reset_operating_conditions.Fields.units_ambient);
                    break;                    
                case 'units_temperature':
                    props.unitsTemperature(props.reset_operating_conditions.Fields.units_temperature);
                    break;
                case 'units_density':
                    props.unitsDensity(props.reset_operating_conditions.Fields.units_density);
                    break;
                case 'units_viscosity':
                    props.unitsViscosity(props.reset_operating_conditions.Fields.units_viscosity);
                    break;
                case 'units_vapor':
                    props.unitsVapor(props.reset_operating_conditions.Fields.units_vapor);
                    break;
                case 'units_density_heading':
                    setTimeout(() => {
                        props.unitsDensityHeading(props.reset_operating_conditions.Fields.units_density_heading)    
                    }, 200);
                    break;
                case 'molecular_weight':
                    setTimeout(() => {
                        props.molecularWeight(props.reset_operating_conditions.Fields.molecular_weight)
                    }, 300);
                    break;
                case "specific_gravity":
                    setTimeout(() => {
                        props.specificGravity(props.reset_operating_conditions.Fields.specific_gravity)
                    }, 300);
                    break;
                case 'custom_fluid_name':
                    props.customFluidName(props.reset_operating_conditions.Fields.custom_fluid_name)
                    break;
                
                case 'units_pressure_atmospheric':
                    props.unitsPressureAtmospheric(props.reset_operating_conditions.Fields.units_pressure_atmospheric)
                    break;
                case 'pressure_atmospheric':
                    props.pressureAtmospheric(props.reset_operating_conditions.Fields.pressure_atmospheric)
                    break;
                case 'units_sonic_velocity':
                    props.unitsSonicVelocity(props.reset_operating_conditions.Fields.units_sonic_velocity);
                    break;
                case 'sonic_velocity':
                    props.sonicVelocity(props.reset_operating_conditions.Fields.sonic_velocity)
                    break;
                case 'composition_method':
                    props.compositionMethod(props.reset_operating_conditions.Fields.composition_method)
                    break;
                case 'database_gasses':
                    props.databaseGasses(props.reset_operating_conditions.Fields.database_gasses)
                    break;
                case 'mole_array_redux':
                    props.moleArrayRedux(props.reset_operating_conditions.Fields.mole_array_redux)
                    break;
                case 'schedule_value':
                    props.scheduleDropdown(props.reset_operating_conditions.Fields.schedule_value)
                    break;
                case 'selected_components':
                    props.selectedComponents(props.reset_operating_conditions.Fields.selected_components)
                    break;
                case "units_gas_pressure":
                    props.unitsGasPressure(props.reset_operating_conditions.Fields.units_gas_pressure)
                    break;
                case "units_gas_temperature":
                    props.unitsGasTemperature(props.reset_operating_conditions.Fields.units_gas_temperature)
                    break;
                case "units_gas_density":
                    props.unitsGasDensity(props.reset_operating_conditions.Fields.units_gas_density)
                    break;
                case "pick_gas_from_db":
                    props.pickGasFromDB(props.reset_operating_conditions.Fields.pick_gas_from_db)
                    break
                default:
                    break;
                    }
            }
            props.resetOperatingConditions({});
            setTimeout(() => {
                setCalcCall(true)    
            }, 1000);
            
        }
        
    }, [props.resetOperatingConditions, props.reset_operating_conditions]);

    // this useEffect builds the current query parameters into an object and returns it to AdvancedPage for comparison & to build the searchParameters redux entry
    useEffect(() => {
        
            let obj = {
                units_flow_rate: props.units_flow_rate,
                units_gas_flow_rate: props.units_gas_flow_rate,
                units_line_pressure: props.units_line_pressure,
                units_ambient: props.units_ambient,
                units_density: props.units_density,
                units_viscosity: props.units_viscosity,
                units_vapor: props.units_vapor,
                units_temperature: props.units_temperature,
                select_technology: props.select_technology,
                fluid_state: props.fluid_state,
                line_size: props.tank?  true: props.line_size,
                process_line_size: props.process_line_size,
                density: props.density,
                max_ambient: props.max_ambient,
                max_density: props.max_density,
                tank: props.tank,
                viscosity: props.viscosity,
                fluid_source: props.fluid_source,
                flanged: props.flanged,
                wafer: props.wafer,
                reducer: props.reducer,
                dual: props.dual,
                quad: props.quad,
                pressure: props.pressure,
                equipment_selection: props.equipment_selection,
                display_all_sensors_no_filters: props.display_all_sensors_no_filters,
                hygienic: props.hygienic,
                hygienic_application: props.hygienic_application,
                full_ambient: props.full_ambient,
                gas_pressure: props.gas_pressure,
                gas_temperature: props.gas_temperature,
                pick_fluids_from_db: props.pick_fluids_from_db,
                min_flow_rate: props.min_flow_rate,
                normal_flow_rate: props.normal_flow_rate,
                max_flow_rate: props.max_flow_rate,
                full_flow_rate: props.full_flow_rate,
                min_line_pressure: props.min_line_pressure,
                normal_line_pressure: props.normal_line_pressure,
                max_line_pressure: props.max_line_pressure,
                full_line_pressure: props.full_line_pressure,
                min_temperature: props.min_temperature,
                normal_temperature: props.normal_temperature,
                max_temperature: props.max_temperature,
                full_temperature: props.full_temperature,
                min_ambient: props.min_ambient,
                normal_ambient: props.normal_ambient,
                flow: props.flow,
                in_line: props.in_line,
                flow_through_chamber_slipstream: props.flow_through_chamber_slipstream,
                product_family: props.product_family,
                conductivity: props.conductivity,
                fluid_service: props.fluid_service,
                high_process_noise_slurry: props.high_process_noise_slurry,
                custom_fluid_name: props.custom_fluid_name,
                units_pressure_atmospheric: props.units_pressure_atmospheric,
                pressure_atmospheric: props.pressure_atmospheric,
                schedule_value: props.select_technology === 'Vortex'? props.schedule_value : '',
                units_sonic_velocity: props.units_sonic_velocity,
                natural_gas_composition_name: props.natural_gas_composition_name,
                composition_method: props.composition_method,
                database_gasses: props.database_gasses,
                mole_array_redux: props.mole_array_redux,
                selected_components: props.selected_components,
                min_density: props.fluid_source.toLowerCase() === 'custom'?props.min_density:'',     
                normal_density: props.fluid_source.toLowerCase() === 'custom'?props.normal_density:'',
                max_density: props.fluid_source.toLowerCase() === 'custom'? props.max_density:'',
                min_vapor: props.fluid_source.toLowerCase() === 'custom'? props.min_vapor:'',
                min_viscosity: props.fluid_source.toLowerCase() === 'custom'?props.min_viscosity:'',
                normal_viscosity: props.fluid_source.toLowerCase() === 'custom'? props.normal_viscosity:'',
                max_viscosity: props.fluid_source.toLowerCase() === 'custom'?props.max_viscosity:'',
                molecular_weight: props.molecular_weight,
                specific_gravity: props.specific_gravity,
                compressibility:  props.compressibility,
                base_compressibility: props.base_compressibility,
                pick_fluids_from_db:props.pick_fluids_from_db,
                natural_gas_compose: props.natural_gas_compose,
                wetted_material: props.select_technology === 'Coriolis' ? props.wetted_material : '',
                gas_density: props.gas_density,
                units_density_heading: resizingFlag?  props.get_sizing_data[0].densty_col_disp : props.units_density_heading,
                non_newtonian_liquid: props.non_newtonian_liquid,
                calculate_density_variation: props.calculate_density_variation,
                pick_gas_from_db: props.pick_gas_from_db,
                sis_application: props.sis_application,
                units_gas_pressure:props.units_gas_pressure,
                units_gas_temperature:props.units_gas_temperature,
                units_gas_density:props.units_gas_density,
           }
           props.operatingConditions(obj);
        
  
      }, [props]);

      
    return (
        <>
            <Validation
                tableData={tableData} 
                calculationResults={calculationResults}
                setApiErrors={setApiErrors}
            />

            <OperatingConditions
                tableData={tableData} 
                calculationResults={calculationResults}
                setCalcCall={setCalcCall}
                displayApiErrors={displayApiErrors}
                userEntered={userEntered}
            />
            <hr />
            <FluidProperties
                userEntered={userEntered}
                setCalcCall={setCalcCall} />
        </>
    );
}

const mapStateToProps = (state) => ({
    search_parameters: state.SizingResults.search_parameters,
    calculate_density_variation:
    state.ProcessVariables.calculate_density_variation,
    display_all_sensors_no_filters:
    state.ApplicationRequirements.display_all_sensors_no_filters,
    min_vapor: state.ProcessVariables.min_vapor,
  equipment_selection: state.EquipmentSelection.equipment_selection,
  select_technology: state.MeasurementType.select_technology,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  min_line_pressure_value: state.ProcessVariables.min_line_pressure_value,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  normal_line_pressure_value: state.ProcessVariables.normal_line_pressure_value,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  max_line_pressure_value: state.ProcessVariables.max_line_pressure_value,
  full_line_pressure: state.ProcessVariables.full_line_pressure,
  full_line_pressure_value: state.ProcessVariables.full_line_pressure_value,
  units_data: state.DropdownData.units_data,
  units_sonic_velocity: state.units_sonic_velocity === undefined ? state.ProcessVariables.units_sonic_velocity : state.units_sonic_velocity,
  min_temperature: state.ProcessVariables.min_temperature,
  normal_temperature: state.ProcessVariables.normal_temperature,
  normal_temperature_value:state.ProcessVariables.normal_temperature_value,
  max_temperature: state.ProcessVariables.max_temperature,
  full_temperature: state.ProcessVariables.full_temperature,
  min_viscosity: state.ProcessVariables.min_viscosity,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  max_viscosity: state.ProcessVariables.max_viscosity,
  min_ambient: state.ProcessVariables.min_ambient,
  normal_ambient: state.ProcessVariables.normal_ambient,
  max_ambient: state.ProcessVariables.max_ambient,
  full_ambient: state.ProcessVariables.full_ambient,
  fluid_state: state.FluidSelection.fluid_state,
  pick_fluids_coolprops_flag: state.FluidSelection.pick_fluids_coolprops_flag,
  fluid_source: state.FluidSelection.fluid_source,
  conductivity: state.FluidSelection.conductivity,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  normal_density: state.ProcessVariables.normal_density,
  gas_pressure: state.ProcessVariables.gas_pressure,
  gas_temperature: state.ProcessVariables.gas_temperature,
  gas_density: state.ProcessVariables.gas_density,
  min_density: state.ProcessVariables.min_density,
  min_density_value: state.ProcessVariables.min_density_value,
  max_density: state.ProcessVariables.max_density,
  max_density_value: state.ProcessVariables.max_density_value,
  units_ambient: state.ProcessVariables.units_ambient, 
  flanged: state.ApplicationRequirements.flanged,
  wafer: state.ApplicationRequirements.wafer,
  reducer: state.ApplicationRequirements.reducer,
  dual: state.ApplicationRequirements.dual,
  quad: state.ApplicationRequirements.quad,
  pressure: state.ApplicationRequirements.pressure,
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  tank: state.ApplicationRequirements.tank,
  line_size: state.ApplicationRequirements.line_size,
  process_line_size: state.ApplicationRequirements.process_line_size,
  schedule_value: state.ApplicationRequirements.schedule_value,
  in_line: state.ApplicationRequirements.in_line,
  flow_through_chamber_slipstream:
    state.ApplicationRequirements.flow_through_chamber_slipstream,
  hygienic: state.ApplicationRequirements.hygienic,
  hygienic_application: state.ApplicationRequirements.hygienic_application,
  reset_operating_conditions: state.ProcessVariables.reset_operating_conditions,
  product_family: state.ApplicationRequirements.product_family,
  fluid_service: state.FluidSelection.fluid_service,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  units_density_heading: state.ProcessVariables.units_density_heading,
  compressibility: state.ProcessVariables.compressibility,
  base_compressibility: state.ProcessVariables.base_compressibility,
  high_process_noise_slurry:
    state.ApplicationRequirements.high_process_noise_slurry,
    custom_fluid_name: state.FluidSelection.custom_fluid_name,
    was_clicked: state.SizingResults.was_clicked,
    flow_rate_min_class_list: state.ProcessVariables.flow_rate_min_class_list,
    flow_rate_norm_class_list: state.ProcessVariables.flow_rate_norm_class_list,
    flow_rate_max_class_list: state.ProcessVariables.flow_rate_max_class_list,
    flow_rate_full_class_list: state.ProcessVariables.flow_rate_full_class_list,
    flow_rate_min_errors: state.ProcessVariables.flow_rate_min_errors,
    flow_rate_norm_errors: state.ProcessVariables.flow_rate_norm_errors,
    flow_rate_max_errors: state.ProcessVariables.flow_rate_max_errors,
    flow_rate_full_errors: state.ProcessVariables.flow_rate_full_errors,
    flow_rate_dropdown_array: state.ProcessVariables.flow_rate_dropdown_array,
    line_press_min_class_list: state.ProcessVariables.line_press_min_class_list,
    line_press_norm_class_list: state.ProcessVariables.line_press_norm_class_list,
    line_press_max_class_list: state.ProcessVariables.line_press_max_class_list,
    line_press_full_class_list: state.ProcessVariables.line_press_full_class_list,
    line_press_min_errors: state.ProcessVariables.line_press_min_errors,
    line_press_norm_errors: state.ProcessVariables.line_press_norm_errors,
    line_press_max_errors: state.ProcessVariables.line_press_max_errors,
    line_press_full_errors: state.ProcessVariables.line_press_full_errors,
    line_press_dropdown_array: state.ProcessVariables.line_press_dropdown_array,
    temperature_min_class_list: state.ProcessVariables.temperature_min_class_list,
    temperature_norm_class_list: state.ProcessVariables.temperature_norm_class_list,
    temperature_max_class_list: state.ProcessVariables.temperature_max_class_list,
    temperature_full_class_list: state.ProcessVariables.temperature_full_class_list,
    temperature_min_errors: state.ProcessVariables.temperature_min_errors,
    temperature_norm_errors: state.ProcessVariables.temperature_norm_errors,
    temperature_max_errors: state.ProcessVariables.temperature_max_errors,
    temperature_full_errors: state.ProcessVariables.temperature_full_errors,
    temperature_dropdown_array: state.ProcessVariables.temperature_dropdown_array,
    ambient_temperature_min_class_list: state.ProcessVariables.ambient_temperature_min_class_list,
    ambient_temperature_norm_class_list: state.ProcessVariables.ambient_temperature_norm_class_list,
    ambient_temperature_max_class_list: state.ProcessVariables.ambient_temperature_max_class_list,
    ambient_temperature_full_class_list: state.ProcessVariables.ambient_temperature_full_class_list,
    ambient_temperature_min_errors: state.ProcessVariables.ambient_temperature_min_errors,
    ambient_temperature_norm_errors: state.ProcessVariables.ambient_temperature_norm_errors,
    ambient_temperature_max_errors: state.ProcessVariables.ambient_temperature_max_errors,
    ambient_temperature_full_errors: state.ProcessVariables.ambient_temperature_full_errors,
    ambient_temperature_dropdown_array: state.ProcessVariables.ambient_temperature_dropdown_array,
    get_sizing_data: state.MySizings.get_sizing_data,
    saved_preferences: state.Preferences.saved_preferences,
    units_pressure_atmospheric: state.ProcessVariables.units_pressure_atmospheric,
    pressure_atmospheric: state.ProcessVariables.pressure_atmospheric,
    units_line_pressure: state.ProcessVariables.units_line_pressure,
    units_temperature: state.ProcessVariables.units_temperature,
    units_density: state.ProcessVariables.units_density,
    units_viscosity: state.ProcessVariables.units_viscosity,
    units_vapor: state.ProcessVariables.units_vapor,
    units_gas_flow_rate: state.ProcessVariables.units_gas_flow_rate,
    flow_rate_decimal: state.Preferences.flow_rate_decimal,
    temperature_decimal: state.Preferences.temperature_decimal,
    line_pressure_decimal: state.Preferences.line_pressure_decimal,
    all_other_decimal: state.Preferences.all_other_decimal,
    units_gas_pressure: state.ProcessVariables.units_gas_pressure,
    units_gas_temperature: state.ProcessVariables.units_gas_temperature,
    units_gas_density: state.ProcessVariables.units_gas_density,
    clear_all_field: state.ClearAllField.clear_all_field,
    pick_fluids_coolprops_flag: state.FluidSelection.pick_fluids_coolprops_flag,
    coolprops_visc: state.FluidSelection.coolprops_visc,
    user_language: state.AppHeader.user_language,
    query_params: state.Preferences.query_params,
    composition_method: state.FluidSelection.composition_method,
    mole_array_redux: state.FluidSelection.mole_array_redux,
    selected_database_natural_gas: state.FluidSelection.selected_database_natural_gas,
    natural_gas_composition_name:
    state.FluidSelection.natural_gas_composition_name,
    selected_components: state.FluidSelection.selected_components,
    molecular_weight: state.ProcessVariables.molecular_weight,
    specific_gravity: state.ProcessVariables.specific_gravity,
    density_specific_gravity: state.ProcessVariables.density_sg,
    units_gas_density_sg: state.ProcessVariables.units_gas_density_sg,
    convert_disable_button : state.ProcessVariables.convert_disable_button,
    sonic_velocity : state.ProcessVariables.sonic_velocity,
    pick_gas_from_db: state.FluidSelection.pick_gas_from_db,
    fluid_selection_flag: state.FluidSelection.fluid_selection_flag,
    wetted_material: state.ApplicationRequirements.wetted_material,
    non_newtonian_liquid: state.ProcessVariables.non_newtonian_liquid,
    calculation_call: state.ProcessVariables.calculation_call,
    natural_gas_compose: state.FluidSelection.natural_gas_compose,
    sizing_results_clicked: state.ProcessVariables.sizing_results_clicked,
    relative_density: state.FluidSelection.relative_density,
    mole_percent_CO2: state.FluidSelection.mole_percent_CO2,
    heating_value: state.FluidSelection.heating_value,
    mole_percent_H2: state.FluidSelection.mole_percent_H2,
    mole_percent_CO: state.FluidSelection.mole_percent_CO,
    heating_value_dropdown: state.FluidSelection.heating_value_dropdown,
    mole_percent_N2: state.FluidSelection.mole_percent_N2,
    sis_application: state.ApplicationRequirements.sis_application,
});

export default connect(mapStateToProps, {
    selectTechnology,
    disableSizingResultsButton,
    minFlowRate,
    normalFlowRate,
    maxFlowRate,
    fullFlowRate,
    unitsFlowRate,
    minLinePressure,
    normalLinePressure,
    maxLinePressure,
    fullLinePressure,
    flowRateDecimalFactorPref,
    temperatureDecimalFactorPref,
    linePressureDecimalFactorPref,
    unitsLinePressure,
    minTemperature,
    normalTemperature,
    maxTemperature,
    fullTemperature,
    unitsTemperature,
    minAmbient,
    normalAmbient,
    maxAmbient,
    fullAmbient,
    unitsAmbient,
    unitsDensity,
    unitsViscosity,
    unitsVapor,
    customFluidName,
    fluidSource,
    calculateDensityVariation,
    dropdownData,
    minDensity,
    minDensityValue,
    normalDensity,
    normalDensityValue,
    maxDensity,
    maxDensityValue,
    minViscosity,
    minViscosityValue,
    normalViscosity,
    normalViscosityValue,
    maxViscosity,
    maxViscosityValue,
    unitsGasFlowRate,
    minVapor,
    errorMessage,
    translationData,
    transError,
    clearAllField,
    baseReferenceConditions,
    pickFluidsFromDB,
    productFamily,
    processLineSize,
    lineSize,
    toggleHygienic,
    toggleFlanged,
    toggleWafer,
    toggleFlow,
    toggleDensity,
    toggleViscosity,
    toggleReducer,
    toggleDual,
    toggleQuad,
    tooglePressure,
    toggleInLine,
    toggleDisplayAllSensorsNoFilters,
    toggleFlowThroughChamberSlipstream,
    toggleTank,
    unitsPressureAtmospheric,
    pressureAtmospheric,
    unitsSonicVelocity,
    equipmentSelection,
    fluidService,
    toggleHighProcessNoiseSlurry,
    toggleHygienicApplication,
    resetOperatingConditions,
    fluidState,
    conductivityDropdown,
    sonicVelocity,
    sonicVelocityMap,
    sonicVelocityValue,
    operatingConditions,    
    linePressMinErrors,
    linePressNormErrors,
    linePressMaxErrors,
    temperatureMinErrors,
    temperatureNormErrors,
    temperatureMaxErrors,
    linePressMinClassList,
    linePressNormClassList,
    linePressMaxClassList,
    temperatureMinClassList,
    temperatureNormClassList,
    temperatureMaxClassList,    
    customErrors,
    gasDensity,
    gasPressure,
    gasTemperature,
    gasDensityValue,
    compositionMethod,
    databaseGasses,
    moleArrayRedux,
    selectedDatabaseNaturalGas,
    naturalGasCompositionName,
    selectedComponents, 
    gasCompressibility, 
    densitySG,
    densitySGValue,
    maxPressureDrop, 
    wettedMaterial, 
    linePressRow,
    tempRowF,
    unitsDensityHeading,
    molecularWeight,
    specificGravity,
    nonNewtonianLiquid,
    revertNonNewtonianCheckboxFlag,
    scheduleDropdown,
    setCalculationFlag,
    minVaporValue,
    unitsGasPressure,
    unitsGasTemperature,
    unitsGasDensity,
    pickGasFromDB

})(OperatingConditionsData);

