import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  ListGroup,
  InputGroup,
  Button,
  OverlayTrigger,
  Tooltip,
  Container,
} from 'react-bootstrap';
import axios from 'axios';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { decimalPlaceNumeric } from '../../UtilityFuntions/decimalFactor';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import {
  DEFAULT_DECIMAL,
  NGAS_COMP_METHOD_MESSAGES,
  GAS_COMPOSITION_NORMALIZE,
} from '../../constants/constants';
import NgasComposionMethodErrorMessage from '../../components/CustomTooltip/NgasComposionMethodErrorMessage';
import ErrorModal from '../../components/CustomModals/PreferencesModal';
import { selectedComponents ,moleArrayRedux,molecularWeight, totalMole,compositionMethodSection, compositionMethodFlag} from '../../redux/actions';
import saveToDatabase from './saveToDatabase';
function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const GasCompositionCompSelection = (props) => {
  const [availableComponents, setAvailableComponents] = useState([]);
  const [allComponents, setAllComponents] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [availableComponent, setAvailableComponent] = useState('');
  const [selectedComponent, setSelectedComponent] = useState('');
  const [moleArray, setMoleArray] = useState([]);
  const [totalMoles, setTotalMoles] = useState(0);
  const [totalMoleWeight, setTotalMoleWeight] = useState(0);
  const [decimalPlaces, setDecimalPlaces] = useState(DEFAULT_DECIMAL);
  const [errorMolelState, setErrorMolelState] = useState(false);
  const prevCompMethod = usePrevious(props.composition_method);
  const [errorMessage, setErrorMessage] = useState('');
  const [normalizeStatus, setNormalizestatus] = useState(false);
  const [normalizeEmpty, setNormalizeEmpty] = useState(false);
  const { ngasCompMethodData } = props;

  useEffect(() => {
    setDecimalPlaces(
      props.saved_preferences
        ? decimalPlaceNumeric(props.saved_preferences.decimalfactor)
        : DEFAULT_DECIMAL
    );
  }, [props.saved_preferences]);

  useEffect(() => {
    let availableComponentsArray = [];
    if (props.natural_gas_components) {
      props.natural_gas_components.map((option) => {
        availableComponentsArray.push(option);
      });
      setAllComponents(availableComponentsArray);
      setAvailableComponents(availableComponentsArray);
    }
  }, [props.natural_gas_components]);

  // summing the moles on any change to the mole array
  useEffect(() => {
    let totalMol = 0;
    setSelectedComponents(selectedComponents)
    props.selectedComponents(moleArray)
    const moleCheck = moleArray.filter((item) => item.mole === null);
    if (moleCheck.length === 0) {
      moleArray.map((moleObj) => {
        totalMol = parseFloat(totalMol) + parseFloat(moleObj.mole);
      });
      setTotalMoles(totalMol); 
      props.totalMole(totalMol)
    }
    
  }, [moleArray]);

  //use Effect to handle back to sizing 
  useEffect(() => {
    let obj = {}
    if(!props.composition_method_flag){
      obj.availableComponents = availableComponents
      obj.selectedComponents = selectedComponents
      obj.selectedComponentsState = props.selected_components
      props.compositionMethodSection(obj)
    }
  }, [props.composition_method_flag, availableComponents, selectedComponents, props.selected_components])
  

  useEffect(() => {

    if(props.composition_method_flag){
      setTimeout(() => {
        if(Object.keys(props.composition_method_section).length !== 0){
          setAvailableComponents(props.composition_method_section.availableComponents)
          setSelectedComponents(props.composition_method_section.selectedComponents)
          props.selectedComponents(props.composition_method_section.selectedComponentsState)
          setMoleArray(props.composition_method_section.selectedComponentsState)   
          setTotalMoleWeight(props.molecular_weight !==''?props.molecular_weight:0)
      }
      props.compositionMethodFlag(false)
      }, 500);
    }

  }, [props.composition_method_flag])



  const removeFromAvailable = (item) => {

    setAvailableComponents(
      availableComponents.filter(
        (value) => value.lookup_code !== item.lookup_code
      )
    );
  }; 

  const addToSelected = (item) => {
    enteredMoles(null, item.SortOrder, item.mol_weight, item.meaning, false);
    setSelectedComponents([...selectedComponents, item]);
  };

  const addToAvailable = (item) => {
    setAvailableComponents(
      [...availableComponents, item].sort(
        (a, b) => parseFloat(a.SortOrder) - parseFloat(b.SortOrder)
      )
    );
  };

  const removeFromSelected = (item) => {
    setMoleArray(removeFromArray(moleArray, item.SortOrder));
    setSelectedComponents(
      selectedComponents.filter(
        (value) => value.lookup_code !== item.lookup_code
      )
    );
  };

  const availableComponentClicked = (element) => {
    setAvailableComponent(element);
  };

  const handleDoubleClick = (element) => {
    availableToSelected();
    setNormalizeEmpty(false);
  };

  const selectedComponentClicked = (element) => {
    setSelectedComponent(element);
  };

  const availableToSelected = () => {
    if (availableComponent) {
      removeFromAvailable(availableComponent);
      addToSelected(availableComponent);
      setAvailableComponent('');
    }
  };

  const selectedToAvailable = () => {
    if (selectedComponent) {
      removeFromSelected(selectedComponent);
      addToAvailable(selectedComponent);
      setSelectedComponent('');
    }
  };

  // To Populate the data when resizing
  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length > 0) {
      let data = props.get_sizing_data[0];
      let camp = []
      let res = []
      let result = props.natural_gas_components.map(c => {
        data.methane!==null&&'methane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.methane, errorTip: false
        }) && res.push(c);
        data.nitrogen!==null&&'nitrogen' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.nitrogen, errorTip: false
        }) && res.push(c);
        data.carbon_dioxide!==null&&'carbon_dioxide' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.carbon_dioxide, errorTip: false
        }) && res.push(c);
        data.ethane!==null&&'ethane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.ethane, errorTip: false
        }) && res.push(c);
        data.propane!==null&&'propane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.propane, errorTip: false
        }) && res.push(c);
        data.water!==null&&'water' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.water, errorTip: false
        }) && res.push(c);
        data.hydrogen_sulfide!==null&&'hydrogen_sulfide' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.hydrogen_sulfide, errorTip: false
        }) && res.push(c);
        data.hydrogen!==null&&'hydrogen' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.hydrogen, errorTip: false
        }) && res.push(c);
        data.carbon_monoxide!==null&&'carbon_monoxide' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.carbon_monoxide, errorTip: false
        }) && res.push(c);
        data.oxygen!==null&&'oxygen' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.oxygen, errorTip: false
        }) && res.push(c);
        data.butane!==null&&'butane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.butane, errorTip: false
        }) && res.push(c);
        data.n_butane!==null&&'n_butane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.n_butane, errorTip: false
        }) && res.push(c);
        data.i_butane!==null&&'i_butane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.i_butane, errorTip: false
        }) && res.push(c);
        data.pentane!==null&&'pentane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.pentane, errorTip: false
        }) && res.push(c);
        data.n_pentane!==null&&'n_pentane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.n_pentane, errorTip: false
        }) && res.push(c);
        data.i_pentane!==null&&'i_pentane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.i_pentane, errorTip: false
        }) && res.push(c);
        data.n_hexane!==null&&'n_hexane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.n_hexane, errorTip: false
        }) && res.push(c);
        data.n_heptane!==null&&'n_heptane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.n_heptane, errorTip: false
        }) && res.push(c);
        data.n_octane!==null&&'n_octane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.n_octane, errorTip: false
        }) && res.push(c);
        data.n_nonane!==null&&'n_nonane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.n_nonane, errorTip: false
        }) && res.push(c);
        data.n_decane!==null&&'n_decane' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.n_decane, errorTip: false
        }) && res.push(c);
        data.helium!==null&&'helium' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.helium, errorTip: false
        }) && res.push(c);
        data.argon!==null&&'argon' === c.lookup_code.toLowerCase() && camp.push({
          moleClass: 'mole-field', id: c.meaning, mol_weight: c.mol_weight,
          idValue: c.meaning.toLowerCase(), index: c.SortOrder, mole: data.argon, errorTip: false
        }) && res.push(c);
   })
      if (props.natural_gas_components.length > 0) {
        setSelectedComponents(res);
        let arr = props.natural_gas_components
        const cars1IDs = new Set(res.map(({ meaning }) => meaning));
        const combined = [
          ...arr.filter(({ meaning }) => !cars1IDs.has(meaning))
        ];
        setAvailableComponents(combined)
        camp.map(async (c) => {
          await enteredMoles(
            c.mole,
            c.index,
            c.mol_weight,
            c.id,
            false
          );
        })
      setTotalMoles(data.total_mole??0); 
      props.totalMole(data.total_mole??0);
      setTotalMoleWeight(data.molecular_weight??data.molec_weight)
      props.molecularWeight(data.molecular_weight??data.molec_weight)
      }
    }
  }, [props.get_sizing_data]);

  const handleClear = () => {
    setSelectedComponents([]);
    setAvailableComponents(allComponents);
    setMoleArray([]);
    setNormalizeEmpty(false);
  };

  const normalizeErrorCheck = (arr) => {
    const Normalize = [];
    const NormalizeList = ['Methane'];
    for (let i = 0; i < NormalizeList.length; i++) {
      if (!arr.some((val) => val.id === NormalizeList[i])) {
        Normalize.push(' ' + NormalizeList[i]);
      }
    }

    if (Normalize.length > 0) {
      setErrorMolelState(true);
      setErrorMessage(
        CustomTranslationText('XXSPECIFY_MMI_PR_MSG_NOR_BTN',props)
      );
      return true;
    } else {
      setErrorMolelState(false);
      return false;
    }
  };
  // placeholder for future normalization work
  const handleNormalize = () => {
    const objValues = {};
    const moleTest = [...moleArray];
    moleTest
      .filter((item) => item.mole === null)
      .map((data) => (data.errorTip = true));

    const moleCheck =
      moleArray.length > 0 ? moleArray.some((val) => val.mole === null) : true;
    const NormalizeCheck = normalizeErrorCheck(moleTest);

    if (!moleCheck && !NormalizeCheck) {
      moleArray.forEach((data) => {
        objValues[data.id.toLowerCase().replace(' ', '_')] = data.mole;
      });
      objValues.composition_method = props.composition_method
      const fetchData = async () => {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}${GAS_COMPOSITION_NORMALIZE}`,
          headers: {
            'Content-Type': 'application/json',
          },
          xsrfHeaderName: 'X-CSRFToken',
          xsrfCookieName: 'csrftoken',
          data: objValues,
        })
          .then((response) => {
            normalizationData(response.data.data);
            setNormalizestatus(true);
          })
          .catch((error) => {
            console.log('error', error);
          });
      };
      fetchData();
    } else {
      setNormalizeEmpty(true);
    }
  };


  const SaveToDatabase = async () => {
    const objValues = {};
    if (props.natural_gas_composition_name !== '' && parseFloat(totalMoles).toFixed(decimalPlaces) ===parseFloat(100).toFixed(decimalPlaces)) {
      moleArray.forEach((data) => {
        objValues[data.id.toLowerCase().replace(' ', '_')] = data.mole;
      });
      const Results = await saveToDatabase(
        objValues,
        props,
        totalMoles,
        totalMoleWeight
      );

      if (Results) {
        setErrorMolelState(true);
        setErrorMessage(Results);
      }
    } else if (props.natural_gas_composition_name === '') {
      setErrorMolelState(true);
      setErrorMessage(CustomTranslationText('XXSPECIFY_MMI_PR_NAT_NAME_LBL',props));
    } else if (parseFloat(totalMoles).toFixed(decimalPlaces) !== parseFloat(100).toFixed(decimalPlaces)) {
      setErrorMolelState(true);
      setErrorMessage(
        CustomTranslationText('XXSPECIFY_MMI_MC_LBL_NORMPRC',props)
      );
    }
  };

  const normalizationData = (norm) => {
    const moleData = [...moleArray];
    moleData.forEach((item, idx) => {
      if (item.idValue === Object.keys(norm)[idx]) {
        item.mole = norm[item.idValue].normalized_pct.toFixed(decimalPlaces);
      }
    });
    setMoleArray(moleData);
    setTotalMoleWeight(norm.molecular_weight)
    props.molecularWeight(norm.molecular_weight)
  };

  
  useEffect(() => {
    if (props.selected_components.length > 0&&props.is_selected) {
      setMoleArray(props.selected_components)      
    }
  }, [props.selected_components])

  const enteredMoles = async(mole, index, mol_weight, id, errorTip) => {

    mole = mole ? mole : null;
    let moleClass = 'mole-field';
    let idValue = id.toLowerCase().replace(' ', '_');
    let obj = { index, mole, mol_weight, id, errorTip, idValue, moleClass };
    if (moleArray.find((ele) => ele.index === index)) {
      setMoleArray(removeFromArray(moleArray, index));
    }
    setMoleArray((a) => [...a, obj]);
   
  };

  const removeFromArray = (arr, remove) => {
    return arr.filter((item) => item.index !== remove);
  };

  useEffect(() => {
    const languageCode = props.user_language && props.user_language.value
    ? props.user_language.value
    : 'US';
    if (props.composition_method) {
      if (
        props.composition_method !== prevCompMethod &&
        prevCompMethod !== undefined
      ) {
        setSelectedComponents([]);
        setNormalizeEmpty(false);
        setAvailableComponents(allComponents);
        setMoleArray([]);
      }
    }
  }, [props.composition_method]);

  
  useEffect(() => {
    if (props.is_selected) {
      setMoleArray([]);
      setAvailableComponents([])
      setSelectedComponents([])
      props.selectedComponents([])
      if (props.database_gasses_list.length > 0) {
        let data = props.database_gasses_list.filter((option) => {
          return (
            option.composition_method === props.composition_method &&
            option.ngas_list_name === props.database_gasses
          );
        });
        var array = data.length>0?Object.keys(data[0]).filter(function (key) {
          return data[0][key] > 0;
        }):[];
        let result = props.natural_gas_components.filter((option) => {
          let opt = option.lookup_code.toLowerCase();
          return array.includes(opt);
        });
        setSelectedComponents(result)
        let arr=props.natural_gas_components
        const cars1IDs = new Set(result.map(({ meaning }) => meaning));
        const combined = [
          ...arr.filter(({ meaning }) => !cars1IDs.has(meaning))
        ];
        setAvailableComponents(combined)
        result.map(async (c)=>{
          await enteredMoles(
            data[0][c.lookup_code.toLowerCase()],
            c.SortOrder,
            c.mol_weight,
            c.meaning,
            false
          );
        })
      }
    }
  }, [props.is_selected]);

  const messageSetup = (comName, value, id) => {
    ngasCompMethodData
      .filter((itm) => itm.name === comName)
      .map((data) => {
        if ( parseFloat(data.min) > parseFloat(value) ||  parseFloat(data.max) < parseFloat(value)) {
          moleArray
            .filter((ele) => ele.id === id)
            .map((itm) => (itm.errorTip = true));
          setMoleArray(moleArray);
          setSelectedComponents([...selectedComponents]);
        } else {
          moleArray
            .filter((ele) => ele.id === id)
            .map((itm) => (itm.errorTip = false));
          setMoleArray(moleArray);
        }
      });
  };

  const errorMessageSetup = (comName, value, id) => {
    moleArray
      .filter((ele) => ele.id === id)
      .map((itm) => (itm.errorTip = true));
    setMoleArray(moleArray);
  };

  const handleBlur = (event) => {
    let comName = '';
    switch (event.target.id) {
      case 'Methane':
      case 'Ethane':
      case 'Nitrogen':
      case 'Propane':
      case 'Water':
      case 'Hydrogen':
      case 'Oxygen':
      case 'Helium':
      case 'Argon':
        comName = event.target.id.toLowerCase();
        messageSetup(comName, event.target.value, event.target.id);
        break;
      case 'Carbon Dioxide':
      case 'Carbon Monoxide':
      case 'Hydrogen Sulfide':
        comName = event.target.id.toLowerCase().replace(' ', '_');
        if (event.target.value !== '') {
          messageSetup(comName, event.target.value, event.target.id);
        }
        break;
      case 'i-Butane':
      case 'n-Butane':
      case 'i-Pentane':
      case 'n-Pentane':
      case 'n-Hexane':
      case 'n-Heptane':
      case 'n-Octane':
      case 'n-Nonane':
      case 'n-Decane':
        comName = event.target.id.toLowerCase();
        if (event.target.value !== '') {
          messageSetup(comName, event.target.value, event.target.id);
        }
        break;
      default:
    }
  };

  const getByValueError = (arr, value) => {
    const errCheck = arr
      .filter((o) => o.id === value)
      .some((arrVal) => arrVal.mole === null);
    if (errCheck) {
      let result = arr
        .filter((o) => o.id === value)
        .some((arrVal) => arrVal.errorTip === true && arrVal.mole === null);
      return result;
    } else {
      let result = arr
        .filter((o) => o.id == value)
        .some((arrVal) => arrVal.errorTip === true);
      return result;
    }
  };

  const getErrorMessage = (value) => {
    let compName = '';
    if (value.indexOf(' ') >= 0) {
      compName = value.toLowerCase().replace(' ', '_');
    } else if (value.indexOf('-') >= 0) {
      compName = value.toLowerCase();
    } else {
      compName = value.toLowerCase();
    }
    const moleValue = moleArray
      .filter((filtr) => filtr.id === value)
      .some((val) => parseInt(val.mole) >= 0);
    if (moleValue) {
      const result = ngasCompMethodData
        ? ngasCompMethodData
            .filter((itm) => itm.name === compName)
            .map((items) => items.message)
        : CustomTranslationText('XXSPECIFY_MMI_PR_LOAD', props);
      return result.length > 0 ? [result] : CustomTranslationText('XXSPECIFY_MMI_PR_LOAD', props);
    } else {
      return ['Please provide Mole (numeric value)'];
    }
  };

  const handleErrorClose = () => {
    setErrorMolelState(false);
    setErrorMessage('');
  };

  const handleErrorShow = () => {
    setErrorMolelState(true);
  };

  return (
    <>
      <ErrorModal
        show={errorMolelState}
        handleClose={handleErrorClose}
        handleShow={handleErrorShow}
        message={errorMessage}
      />
      <Row>
        <Col sm={5} md={5}>
          <div className='available-components-title'>
            <CustomTranslation data='XXSPECIFY_MMI_COM_AVAIL_CMPNTS' />
          </div>
          <ListGroup className='available-components'>
            {availableComponents.map((element) => (
              <ListGroup.Item
                onDoubleClick={() => handleDoubleClick(element)}
                action
                key={element.SortOrder}
                onClick={() => availableComponentClicked(element)}
                className={
                  availableComponent.lookup_code === element.lookup_code
                    ? 'active'
                    : ''
                }
                index={element.SortOrder}
              >
                {element.meaning}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col sm={2} md={2}>
          <div className='transfer-buttons text-center'>
            <CustomButton
              classNames='btn btn-component-select-arrow-right btn-arrow text-center'
              label=''
              onClick={() => availableToSelected()}
              variant='outline-dark'
              arrow='right'
            />
            <br />
            <CustomButton
              classNames='btn btn-component-select-arrow-left btn-arrow text-center'
              label=''
              onClick={() => selectedToAvailable()}
              variant='outline-dark'
              arrow='left'
            />
          </div>
        </Col>
        <Col sm={5} md={5}>
          <div className='available-components-title'>
            <CustomTranslation data='XXSPECIFY_MMI_COM_SEL_CMPNTS' />{' '}
          </div>
          <InputGroup>
            <div className='selected-components'>
              <ListGroup.Item className='selected-header'>
                <span>
                  <CustomTranslation data='XXSPECIFY_MMI_COM_CMPNT' />
                </span>
                <span className='mole-header'>
                  <CustomTranslation data='XXSPECIFY_MMI_COM_MOLE' />
                </span>
              </ListGroup.Item>
              {selectedComponents.map((element) => (
                <InputGroup.Append key={element.SortOrder}>
                  <ListGroup.Item
                    action
                    onClick={() => selectedComponentClicked(element)}
                    className={`${
                      selectedComponent.lookup_code === element.lookup_code
                        ? 'active'
                        : ''
                    } ${element.lookup_code}`}
                    index={element.SortOrder}
                  >
                    {element.meaning}
                    <OverlayTrigger
                      placement={
                        getByValueError(moleArray, element.meaning)
                          ? 'top'
                          : 'bottom'
                      }
                      trigger={
                        moleArray
                          .filter((filtr) => filtr.id === element.meaning)
                          .some(
                            (val) =>
                              parseInt(val.mole) >= 0 ||
                              (val.mole === null && val.errorTip === true)
                          )
                          ? ['hover', 'focus']
                          : ''
                      }
                      overlay={
                        <Tooltip
                          id={`tooltip-error-${element.meaning}`}
                          className={
                            getByValueError(moleArray, element.meaning)
                              ? 'error-tooltip'
                              : 'alert-tooltip'
                          }
                        >
                          <NgasComposionMethodErrorMessage
                            message={getErrorMessage(element.meaning)}
                            errorTip={getByValueError(
                              moleArray,
                              element.meaning
                            )}
                          />
                        </Tooltip>
                      }
                    >
                      <input
                        id={element.meaning}
                        className={
                          getByValueError(moleArray, element.meaning)||
                          getByValueError(moleArray, element.meaning)
                            ? 'mole-field-error'
                            : 'mole-field'
                        }
                        onChange={(e) =>
                          enteredMoles(
                            e.target.value,
                            element.SortOrder,
                            element.mol_weight,
                            element.meaning,
                            false
                          )
                        }
                        onBlur={(e) => handleBlur(e)}
                        tabIndex='1'
                        autoComplete='off'
                        value={moleArray
                          .filter((filtr) => filtr.id === element.meaning)
                          .map((data) => data.mole)}
                      />
                    </OverlayTrigger>
                  </ListGroup.Item>
                </InputGroup.Append>
              ))}
            </div>
          </InputGroup>
        </Col>
      </Row>
      <div className='operating-conditions d-none d-sm-block'>
        <Container>
          <Row>
            <Col sm={6} />
            <Col sm={6}>
              <Row>
                <Col sm={6}>
                  <div className='total-mole'>Total Mole%</div>
                </Col>
                <Col sm={6}>
                  <div className='total-mole-result' />
                  {parseInt(totalMoles).toFixed(decimalPlaces)}
                </Col>
              </Row>
              <Row>
                <Col sm={7}>
                  <div className='molecular-weight'>Molecular Weight</div>
                </Col>
                <Col sm={5}>
                  <div className='molecular-weight-result' />
                  {parseFloat(totalMoleWeight).toFixed(decimalPlaces)}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='d-block d-sm-none'>
        <Container>
          <Row>
            <Col>
              <div className='total-mole'>Total Mole%</div>
            </Col>
            <Col>
              <div className='total-mole-result' />
              {parseInt(totalMoles).toFixed(decimalPlaces)}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='molecular-weight'>Molecular Weight</div>
            </Col>
            <Col>
              <div className='molecular-weight-result' />
              {parseFloat(totalMoleWeight).toFixed(decimalPlaces)}
            </Col>
          </Row>
        </Container>
      </div>
      <div className='operating-conditions d-none d-sm-block'>
        <Row>
          <Col sm={12}>
            <CustomButton
              classNames='btn btn-small-fpa btn-arrow btn-custom-gas btn-white-fpa'
              label={CustomTranslationText('XXSPECIFY_MMI_COM_SAVE_TO_DB',props)}
              onClick={SaveToDatabase}
              variant='outline-dark'
              arrow='right'
              size='sm'
            />
            <CustomButton
              classNames='btn btn-small-fpa btn-arrow btn-white-fpa'
              label={<CustomTranslation data='XXSPECIFY_MMI_MC_BTN_CLEAR' />}
              onClick={handleClear}
              variant='outline-dark'
              arrow='right'
              size='sm'
            />
          </Col>
          <Col sm={12}>
            <Button
              className='btn btn-small-fpa btn-mole-normalize'
              size='sm'
              variant='primary'
              disabled={false}
              onClick={handleNormalize}
            >
              <CustomTranslation data='XXSPECIFY_MMI_COM_NORMALIZE' />
            </Button>
          </Col>
        </Row>
      </div>
      <div className='d-block d-sm-none'>
        <Row>
          <Col sm={9}>
            <CustomButton
              classNames='btn btn-small-fpa btn-arrow btn-custom-gas btn-white-fpa'
              label={CustomTranslationText('XXSPECIFY_MMI_COM_SAVE_TO_DB',props)}
              //onClick={onClickNormalize}
              variant='outline-dark'
              arrow='right'
              size='sm'
            />
            <CustomButton
              classNames='btn btn-small-fpa btn-arrow btn-white-fpa'
              label={<CustomTranslation data='XXSPECIFY_MMI_MC_BTN_CLEAR' />}
              onClick={handleClear}
              variant='outline-dark'
              arrow='right'
              size='sm'
            />
          </Col>
          <Col sm={3}>
            <Button
              className='btn btn-small-fpa btn-mole-normalize'
              size='sm'
              variant='primary'
              disabled={false}
              onClick={handleNormalize}
            >
              <CustomTranslation data='XXSPECIFY_MMI_COM_NORMALIZE' />
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  available_components: state.FluidSelection.available_components,
  selected_components: state.FluidSelection.selected_components,
  natural_gas_components: state.FluidSelection.natural_gas_components,
  saved_preferences: state.Preferences.saved_preferences,
  composition_method: state.FluidSelection.composition_method,
  natural_gas_composition_name:
    state.FluidSelection.natural_gas_composition_name,
  database_gasses_list: state.FluidSelection.database_gasses_list,
  database_gasses: state.FluidSelection.database_gasses,
  is_selected: state.FluidSelection.is_selected,
  email:state.AppHeader.email,
  translation_data: state.TranslationData.translation_data,
  user_language: state.AppHeader.user_language,
  query_params: state.Preferences.query_params,
  mole_array_redux: state.FluidSelection.mole_array_redux,
  get_sizing_data: state.MySizings.get_sizing_data,
  translation_data: state.TranslationData.translation_data,
  composition_method_flag: state.FluidSelection.composition_method_flag,
  composition_method_section: state.FluidSelection.composition_method_section,
  molecular_weight: state.FluidSelection.molecular_weight,
});


export default connect(mapStateToProps, { selectedComponents,moleArrayRedux ,molecularWeight, totalMole,compositionMethodSection, compositionMethodFlag})(
  GasCompositionCompSelection
);
