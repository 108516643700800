import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import loader from '../../images/loading.gif';
import { connect } from 'react-redux';
import './ShowMoreRates.scss';
import '../../styles/App.scss';
import pictureComingSoon from '../../images/pictureComingSoon.png';
import { CustomRadiobutton } from '../../components/CustomRadiobutton/CustomRadiobutton';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { initialState, Reducer } from './Reducer';
import { massFlowAccuracy,productImageUrlLoad, showMoreRatesResultData ,flowAccuracyValue,flowAccuracy,densityAccuracy} from '../../redux/actions';
import { usePrevious } from '../../UtilityFuntions/usePrevious';
import ShowMoreRatesTable from './ShowMoreRatesTable';
import { BASE64_IMG_PREFIX } from '../../constants/constants';

function countDecimalPlaces(num) {
  const str = num.toString();

  if (str.indexOf('.') === -1) {
    return 0;
  }

  return str.split('.')[1].length;
}

const ShowMoreRates = (props) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const [flow_accuracy, setFlowAccuracy] = useState([]);
  const [flow_accuracy_check, setFlowAccuracyCheck] = useState('');
  const [density_accuracy_check, setDensityAccuracyCheck] = useState('');
  const [density_accuracy, setDensityAccuracy] = useState([]);
  const [show_more_result, setShowMoreResults] = useState([]);
  const [resizedAcc, setResizedAcc] = useState(false);
  const [densitySelection, setDensitySelection] = useState(false)
  const [pressureUnit, setPressureUnit] = useState('')
  const [flowRateUnit, setFlowRateUnit] = useState({})
  const [gasFlowRateUnit, setGasFlowRateUnit] = useState({})
  const [imgLoader, setImgLoader] = useState(true)
  const [densityAccuracyUnits, setDensityAccuracyUnits] = useState();

  const { all_other_decimal, flow_rate_decimal, temperature_decimal, line_pressure_decimal ,search_parameters, units_density} = props;
  const isChecked = (event) => {
      setDensitySelection(false)
      setFlowAccuracyCheck(event);
    props.flowAccuracyValue(event)
    props.flowAccuracy(event+'%')
  };
  const isCheckedDensity = (event) => {
      setDensitySelection(true)
      setDensityAccuracyCheck(event)
      props.densityAccuracy(event)
      
  };

  useEffect(() => {
    if(!props.is_applied){
      setPressureUnit(props.units_line_pressure)
      setFlowRateUnit(props.units_flow_rate)
      setGasFlowRateUnit(props.units_gas_flow_rate)
    }
  }, [props.is_applied]);
 
  const prevProductImage = usePrevious(state.productImageUrl);

  const floatSort = (rowA, rowB, columnId) => {
    const valueA = parseFloat(rowA.values[columnId]);
    const valueB = parseFloat(rowB.values[columnId]);

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  const coriolis_liquid = useMemo(
    () => [
      {
        Header: 'FLOW RATE',
        accessor: 'flow_rate',
        sortType: floatSort,
      },
      {
        Header: 'MASS FLOW ACCURACY',
        accessor: 'mass_flow_accuracy_rate',
        sortType: floatSort,
      },
      {
        Header: 'PRESSURE DROP',
        accessor: 'pressure_drop',
        sortType: floatSort,
      },
      {
        Header: 'TUBE VELOCITY',
        accessor: 'velocity',
        sortType: floatSort,
      },
      {
        Header: "REYNOLD`S NUMBER",
        accessor: 'reynolds_nbr',
        sortType: floatSort,
      },
    ],
    []
  );
  const density= useMemo(
    () => [
      {
        Header: 'FLOW RATE',
        accessor: 'flow_rate',
        sortType: floatSort,
      },
      
      {
        Header: 'PRESSURE DROP',
        accessor: 'pressure_drop',
        sortType: floatSort,
      },
      {
        Header: 'VELOCITY',
        accessor: 'velocity',
        sortType: floatSort,
      },
      {
        Header: "REYNOLD`S NUMBER",
        accessor: 'reynolds_nbr',
        sortType: floatSort,
      },
    ],
    []
  );
  const densityFDM= useMemo(
    () => [
      {
        Header: 'FLOW RATE',
        accessor: 'flow_rate',
        sortType: floatSort,
      },
      {
        Header: 'VELOCITY',
        accessor: 'velocity',
        sortType: floatSort,
      },
      {
        Header: "REYNOLD`S NUMBER",
        accessor: 'reynolds_nbr',
        sortType: floatSort,
      },
    ],
    []
  );
  const viscosity= useMemo(
    () => [
      {
        Header: 'FLOW RATEE',
        accessor: 'flow_rate',
        sortType: floatSort,
      },
      {
        Header: 'VELOCITY',
        accessor: 'velocity',
        sortType: floatSort,
      },
      {
        Header: "REYNOLD`S NUMBER",
        accessor: 'reynolds_nbr',
        sortType: floatSort,
      },
    ],
    []
  );
  const coriolis_gas = useMemo(
    () => [
      {
        Header: 'FLOW RATE',
        accessor: 'flow_rate',
        sortType: floatSort,
      },
      {
        Header: 'MASS FLOW ACCURACY',
        accessor: 'mass_flow_accuracy_rate',
        sortType: floatSort,
      },
      {
        Header: 'PRESSURE DROP @ MIN DENSITY',
        accessor: 'pressure_drop_min',
        sortType: floatSort,
      },
      {
        Header: 'PRESSURE DROP',
        accessor: 'pressure_drop',
        sortType: floatSort,
      },
      {
        Header: 'PRESSURE DROP @ MAX DENSITY',
        accessor: 'pressure_drop_max',
        sortType: floatSort,
      },
      {
        Header: 'VELOCITY @ MIN DENSITY',
        accessor: 'tube_velocity_min',
        sortType: floatSort,
      },
      {
        Header: 'VELOCITY',
        accessor: 'velocity',
        sortType: floatSort,
      },
      {
        Header: 'VELOCITY @ MAX DENSITY',
        accessor: 'tube_velocity_max',
        sortType: floatSort,
      },
      {
        Header: 'REYNOLD`S NUMBER @ MIN DENSITY',
        accessor: 'reynolds_number_min',
        sortType: floatSort,
      },
      {
        Header: 'REYNOLD`S NUMBER',
        accessor: 'reynolds_nbr',
        sortType: floatSort,
      },
      {
        Header: 'REYNOLD`S NUMBER @ MAX DENSITY',
        accessor: 'reynolds_number_max',
        sortType: floatSort,
      },
    ],
    []
  );
  const magnetic = useMemo(
    () => [
      {
        Header: 'FLOW RATEE',
        accessor: 'flow_rate',
        sortType: floatSort,
      },
      {
        Header: 'VOLUMETRIC FLOW ACCURACY',
        accessor: 'flow_accuracy_rate',
        sortType: floatSort,
      },

      {
        Header: 'VELOCITY',
        accessor: 'velocity',
        sortType: floatSort,
      },
    ],
    []
  );
  const vortex = useMemo(
    () => [
      {
        Header: 'FLOW RATE',
        accessor: 'flow_rate',
        sortType: floatSort,
      },
      {
        Header: 'VOLUMETRIC FLOW ACCURACY',
        accessor: 'flow_accuracy',
        sortType: floatSort,
      },
      {
        Header: 'PRESSURE DROP',
        accessor: 'pressure_drop',
        sortType: floatSort,
      },
      {
        Header: 'VELOCITY',
        accessor: 'velocity',
        sortType: floatSort,
      },
      {
        Header: 'REYNOLD`S NUMBER',
        accessor: 'reynolds_number',
        sortType: floatSort,
      },
    ],
    []
  );
  const velocity = () => {
    let tubeVel = '';
    if(props.fluid_state.toLowerCase()!=='gas'){
      if (props.units_data) {
        for (const [key, value] of Object.entries(props.units_data.unitMapping)) {
          if (key === flowRateUnit.key) {
            if (value === 'METRIC SYSTEM') {
              tubeVel = 'm/sec'
            } else if (value === 'ENGLISH SYSTEM') {
              tubeVel = 'ft/sec'
            }
          }
        }
      }
    }else{
      tubeVel = props.show_more_rates_data !== undefined || null? props.show_more_rates_data.velocity_unit:''
    }
    
    return tubeVel;
  }

  const unitsLinePressureDropAbsolute = () => {
    const value = pressureUnit.key ? pressureUnit.key : pressureUnit;
    if (value) {
      if (value.endsWith('-G') || value.endsWith('-A')) {
        return (value).substring(0, value.length - 2);
      } else if (value.endsWith('G') || value.endsWith('A')) {
        return (value).substring(0, value.length - 1);
      }
    }
  }

  useEffect(()=>{
    let isProductImgLoaded = state.productImageUrl.length > 0 && state.productImageUrl[0].model_code === props.selected_row_data.product;
    let showMoreRatesProduct = props.show_more_rates_data.length > 0 ? props.show_more_rates_data[0].product : ""
    const isShowMoreRatesLoaded = showMoreRatesProduct !== '' ? (search_parameters.select_technology.toLowerCase() === 'magnetic' || search_parameters.select_technology.toLowerCase() === 'vortex')  ? (props.selected_row_data.parent_model === showMoreRatesProduct) : (props.selected_row_data.product === showMoreRatesProduct) : false;
    if((isProductImgLoaded || isShowMoreRatesLoaded) && imgLoader){
      setImgLoader(false)
    } 
}, [state.productImageUrl])

  const tubeVelocity = () => {
    let tubeVel = '';
    if(search_parameters.select_technology ==='Vortex'){
      tubeVel = ''  
    }else{
      try {
        tubeVel = props.show_more_rates_data.length !==0 ? props.show_more_rates_data[0].tube_velocity_units:''  
      } catch (error) {
        // no action 
      }
      
    }
    
    //tubeVel = props.show_more_rates_data !== undefined || null? props.show_more_rates_data.velocity_unit:''

    
    return tubeVel;
  }

  const headers = {
    flow_rate: props.fluid_state.toLowerCase()!=='gas'? flowRateUnit ? flowRateUnit.value:'' : gasFlowRateUnit ? gasFlowRateUnit.value:'',
    pressure_drop: unitsLinePressureDropAbsolute(),
    velocity: velocity(),
    tube_velocity: tubeVelocity()
  };

  var showMoreRatesTableData = []
try {
   showMoreRatesTableData = 
    show_more_result.length  > 0 ? (
      search_parameters.select_technology.toLowerCase() === 'coriolis' &&
      !props.calculate_density_variation ? (
        <ShowMoreRatesTable
          columns={coriolis_liquid}
          data={show_more_result}
          tableType='coriolis-table'
          headers={headers}
        />
      ) : props.calculate_density_variation ? (
        <ShowMoreRatesTable
          columns={coriolis_gas}
          data={show_more_result}
          tableType='coriolis-table'
          headers={headers}
        />
      ) : search_parameters.select_technology.toLowerCase() === 'magnetic' ? (
        <ShowMoreRatesTable
          columns={magnetic}
          data={show_more_result}
          tableType='coriolis-table'
          headers={headers}
        />
      ) : search_parameters.select_technology.toLowerCase() === 'vortex' ? (
        <ShowMoreRatesTable
        columns={vortex}
        data={show_more_result}
        tableType='coriolis-table'
        headers={headers}
      />
      ) : search_parameters.select_technology.toLowerCase() === 'density' && props.show_more_rates_data[0].product !=='FDM'?(
        <ShowMoreRatesTable
          columns={density}
          data={show_more_result}
          tableType='coriolis-table'
          headers={headers}
        />
      ): search_parameters.select_technology.toLowerCase() === 'density' && props.show_more_rates_data[0].product ==='FDM'?(
        <ShowMoreRatesTable
          columns={densityFDM}
          data={show_more_result}
          tableType='coriolis-table'
          headers={headers}
        />
      )
       : search_parameters.select_technology.toLowerCase() === 'viscosity'?(
        <ShowMoreRatesTable
          columns={viscosity}
          data={show_more_result}
          tableType='coriolis-table'
          headers={headers}
        />
      ):(
        <span />
      )
    ) : (
      <span />
    );
} catch (error) {
  // no actions
}
 


  useEffect(() => {
    if (props.product_image_url !== prevProductImage) {
      dispatch({ type: 'productImageUrl', payload: props.product_image_url });
    }
  }, [props.product_image_url]);

  useEffect(() => {
    if(props.get_sizing_data.length > 0){
      let selectedIndex = -1;
      if(props.get_sizing_data[0].parent_model !==null){
        selectedIndex =  props.get_sizing_data[0]?.parent_model?.indexOf(props.show_more_rates_data[0]?.product)
      }else if(props.get_sizing_data[0]?.model_name !== null && props?.get_sizing_data[0]?.model_name !== ''){
        selectedIndex =  props?.get_sizing_data[0]?.model_name?.indexOf(props.show_more_rates_data[0]?.product)
      }

     if(selectedIndex !== -1){
      setResizedAcc(true)
      setDensitySelection(true)
     }
    }
  }, [props.get_sizing_data]);

  useEffect(() => {
    if (Object.keys(props.show_more_rates_data).length > 0&&props.was_clicked===false) {
      let data = [];
      let mass_flow={ min:'--',
      max:'--',
     normal:''}

      

    if (
      Object.keys(props.show_more_rates_data).length > 0 &&
      search_parameters.select_technology.toLowerCase() === 'coriolis' && props.show_more_rates_data[0]
    ) {
      var filtered =
        props.fluid_state.toLowerCase() === 'gas'
          ? []
          : props.show_more_rates_data[0].density_accuracy_radio_button? Array.isArray(props.show_more_rates_data[0].density_accuracy_radio_button) ?
          props.show_more_rates_data[0].density_accuracy_radio_button.filter(
              function (value, index, arr) {
                return value !== null;
              }
            ):[]:[];
            filtered = filtered.map(val => val.toFixed(all_other_decimal)) 
      var filtered1 =
        props.fluid_state.toLowerCase() === 'gas'
          ? []
          :  props.show_more_rates_data[0].flow_accuracy_radio_button ?Array.isArray(props.show_more_rates_data[0].flow_accuracy_radio_button) ?
          props.show_more_rates_data[0].flow_accuracy_radio_button.filter(
              function (value, index, arr) {
                return value !== null;
              }
            ):[]:[];
           
            if(props.show_more_rates_data[0].default_density_accuracy==="Y" && props.flow_accuracy === '' && !resizedAcc && density_accuracy_check == '') {

            if(filtered.length===1){
              setFlowAccuracyCheck([filtered1[0]]);
              props.flowAccuracyValue(filtered1[0])
              setDensityAccuracy(filtered);    
              setFlowAccuracy(filtered1);

              let filtered_result = filtered.map(el => disableRadioButton(el))
              let index_select = filtered_result.findIndex((el) => el === false)
              //flow
              const flow_acc =  props.flow_accuracy_value
              const flow_accuracy_radio_button_array = props.selected_row_data.flow_accuracy_radio_button
              const flow_accuracy_index = flow_accuracy_radio_button_array.indexOf(flow_acc)

              //getting acc data row 
              const accuracy_data_row = props.selected_row_data.accuracy_data[flow_accuracy_index]

              if(accuracy_data_row !== undefined? accuracy_data_row.default_density_accuracy ==='Y':false){
                setDensityAccuracyCheck(filtered[index_select])
                props.densityAccuracy(filtered[index_select])
              }else{
                setDensityAccuracyCheck('')
                props.densityAccuracy('')
              }

             }else{

              if((props.selected_row_data? props.selected_row_data.accuracy_data[0].default_density_accuracy === 'Y' && props.selected_row_data.accuracy_data[0].densityaccuracy_liquid === 1:false) ){
                setFlowAccuracyCheck(filtered1[0]);
                props.flowAccuracyValue(filtered1[0])
                setDensityAccuracyCheck(filtered[0])
                props.densityAccuracy(filtered[0])
                setDensityAccuracy(filtered);
                setFlowAccuracy(filtered1);
              }else{
                let filtered_result = filtered.map(el => disableRadioButton(el))
                let index_select = filtered_result.findIndex((el) => el === false)

                //flow
                const flow_acc =  props.flow_accuracy_value
                const flow_accuracy_radio_button_array = props.selected_row_data.flow_accuracy_radio_button
                const flow_accuracy_index = flow_accuracy_radio_button_array.indexOf(flow_acc)

                //getting acc data row 
                const accuracy_data_row = props.selected_row_data.accuracy_data[flow_accuracy_index]
                if(accuracy_data_row !== undefined? accuracy_data_row.default_density_accuracy ==='Y':false){
                  setDensityAccuracyCheck(filtered[index_select])
                  props.densityAccuracy(filtered[index_select])
                }else{
                  setDensityAccuracyCheck('')
                  props.densityAccuracy('')
                }
                setFlowAccuracyCheck(filtered1[0]);
                props.flowAccuracyValue(filtered1[0])
                setDensityAccuracy(filtered);
                setFlowAccuracy(filtered1);
              }
             }

           }else if(props.show_more_rates_data[0].default_density_accuracy==="Y" && props.flow_accuracy !== '' && density_accuracy_check !== '' && !resizedAcc){
            let filtered_result = filtered.map(el => disableRadioButton(el))
            let index = -1;
            let index_select = filtered_result.findIndex((el) => el === false)
            let selected_index = filtered.indexOf(density_accuracy_check)

            if(filtered_result[selected_index]){
              index = index_select
            }else{
              index = selected_index
            }
            
           //flow
           const flow_acc =  props.flow_accuracy_value
           const flow_accuracy_radio_button_array = props.selected_row_data.flow_accuracy_radio_button
           const flow_accuracy_index = flow_accuracy_radio_button_array.indexOf(flow_acc)

           //getting acc data row 
           const accuracy_data_row = props.selected_row_data.accuracy_data[flow_accuracy_index]

           if(accuracy_data_row !== undefined?accuracy_data_row.default_density_accuracy ==='Y':false){
              setDensityAccuracyCheck(filtered[index])
              props.densityAccuracy(filtered[index])
           }else if(accuracy_data_row !== undefined ? (accuracy_data_row.default_density_accuracy ==='N' && !filtered_result[selected_index]) :false){
              setDensityAccuracyCheck(filtered[selected_index])
              props.densityAccuracy(filtered[selected_index])
           }else{
              setDensityAccuracyCheck('')
              props.densityAccuracy('')
           }
            
           }else if((props.show_more_rates_data[0].default_density_accuracy==="N"||props.show_more_rates_data[0].default_density_accuracy==="") && props.flow_accuracy==='' && !resizedAcc){
            setFlowAccuracyCheck(filtered1[0]);
            props.flowAccuracyValue(filtered1[0])
       
            setDensityAccuracy(filtered);
            if(props.density_accuracy ===''){
              setDensityAccuracyCheck('')
              props.densityAccuracy('')

            }else{
              setDensitySelection(false)
            }
            setFlowAccuracy(filtered1);
            
           }else if((props.show_more_rates_data[0].default_density_accuracy==="N"||props.show_more_rates_data[0].default_density_accuracy==="") && props.flow_accuracy!=='' && !resizedAcc){
            if(!densitySelection){
              setDensityAccuracyCheck('')
              props.densityAccuracy('')
            }else{
              setDensitySelection(false)
            }
           
           }else if(props.flow_accuracy === '' && resizedAcc && props.get_sizing_data.length > 0 && props.default_row) {
            setFlowAccuracyCheck(parseFloat(props.get_sizing_data[0].flow_accuracy))
            props.flowAccuracyValue(parseFloat(props.get_sizing_data[0].flow_accuracy))
            props.flowAccuracy(props.get_sizing_data[0].flow_accuracy+'%')
            setDensityAccuracyCheck(parseFloat(props.get_sizing_data[0].den_accuracy_all_rates).toFixed(all_other_decimal))
            props.densityAccuracy(parseFloat(props.get_sizing_data[0].den_accuracy_all_rates).toFixed(all_other_decimal))
            setResizedAcc(false)
            }else{
              setResizedAcc(false)
            }


      for (let i=1, k = 101; k > 50; k = k - 1) {
        let obj = {};

        try { 
          let index = data.findIndex(i => i.flow_rate == props.show_more_rates_data[0]['display_rate'][k].toFixed(flow_rate_decimal))
          if (
            ((props.show_more_rates_data[0]['display_rate'][k] !=
              props.show_more_rates_data[0]['display_rate'][k - 1] &&
              (props.show_more_rates_data[0]['display_rate'][k] ===
                parseFloat(props.min_flow_rate) ||
                props.show_more_rates_data[0]['display_rate'][k] ===
                parseFloat(props.normal_flow_rate) ||
                (props.max_flow_rate != '' &&
                  props.show_more_rates_data[0]['display_rate'][k] ===
                  parseFloat(props.max_flow_rate))||
                    (props.full_flow_rate != '' &&
                  props.show_more_rates_data[0]['display_rate'][k] ===
                  parseFloat(props.full_flow_rate)))) ||
            k % 5 === 0+1) && index === -1
          ) {
          
              obj.flow_rate = props.show_more_rates_data[0]['display_rate'][k] !==null && props.show_more_rates_data[0]['display_rate'][k] !== undefined ?
            props.show_more_rates_data[0]['display_rate'][k].toFixed(flow_rate_decimal):0;
  
            if (props.show_more_rates_data[0]['dmf_accuracy'][k] === null ) {
              obj.mass_flow_accuracy_rate = 0
            } else if (countDecimalPlaces(props.show_more_rates_data[0]['dmf_accuracy'][k]) <= 2 ) {
              obj.mass_flow_accuracy_rate = props.show_more_rates_data[0]['dmf_accuracy'][k]
            } else {
              obj.mass_flow_accuracy_rate = props.show_more_rates_data[0]['dmf_accuracy'][k].toFixed(all_other_decimal)
            }
            //reynolds number
            obj.reynolds_nbr = props.show_more_rates_data[0]['reynolds_number'][k] !== null && 
            props.show_more_rates_data[0]['reynolds_number'][k] !== undefined? 
            props.show_more_rates_data[0]['reynolds_number'][k].toFixed(all_other_decimal): 0
  
            obj.reynolds_number_min = props.show_more_rates_data[0]['reynolds_number_at_min_density'][k] !== null &&
            props.show_more_rates_data[0]['reynolds_number_at_min_density'][k] !== undefined?   
            props.show_more_rates_data[0]['reynolds_number_at_min_density'][k].toFixed(all_other_decimal):0
  
            obj.reynolds_number_max = props.show_more_rates_data[0]['reynolds_number_at_max_density'][k] !== null &&
            props.show_more_rates_data[0]['reynolds_number_at_max_density'][k] !== undefined?  
            props.show_more_rates_data[0]['reynolds_number_at_max_density'][k].toFixed(all_other_decimal):0;
            //velocity
            obj.velocity = props.show_more_rates_data[0]['tube_velocity'][k] !== null && 
            props.show_more_rates_data[0]['tube_velocity'][k] !== undefined? 
            props.show_more_rates_data[0]['tube_velocity'][k].toFixed(all_other_decimal):0;
  
            obj.tube_velocity_max = props.show_more_rates_data[0]['tube_velocity_at_max_density'][k] !== null &&
            props.show_more_rates_data[0]['tube_velocity_at_max_density'][k] !== undefined? 
            props.show_more_rates_data[0]['tube_velocity_at_max_density'][k].toFixed(all_other_decimal):0;
  
            obj.tube_velocity_min = props.show_more_rates_data[0]['tube_velocity_at_min_density'][k] !== null &&
            props.show_more_rates_data[0]['tube_velocity_at_min_density'][k] !== undefined  ?
            props.show_more_rates_data[0]['tube_velocity_at_min_density'][k].toFixed(all_other_decimal):0;
            //pressure drop
            obj.pressure_drop = props.show_more_rates_data[0]['pressure_drop'][k] !== null &&
            props.show_more_rates_data[0]['pressure_drop'][k] !== undefined? 
            props.show_more_rates_data[0]['pressure_drop'][k].toFixed(line_pressure_decimal):0
            
            obj.pressure_drop_min = props.show_more_rates_data[0]['pressure_drop_at_min_density'][k] !== null &&
            props.show_more_rates_data[0]['pressure_drop_at_min_density'][k] !== undefined? 
            props.show_more_rates_data[0]['pressure_drop_at_min_density'][k].toFixed(line_pressure_decimal):0;
  
            obj.pressure_drop_max = props.show_more_rates_data[0]['pressure_drop_at_max_density'][k] !==null &&
            props.show_more_rates_data[0]['pressure_drop_at_max_density'][k] !== undefined? 
            props.show_more_rates_data[0]['pressure_drop_at_max_density'][k].toFixed(line_pressure_decimal):0;
            obj.sr_no=i++
            
            if(props.fluid_state.toLowerCase()!=='gas'){
              if(parseFloat(obj.flow_rate) === parseFloat(props.min_flow_rate)) 
                {mass_flow.min=obj.mass_flow_accuracy_rate+'%'}
              if(parseFloat(obj.flow_rate) === parseFloat(props.normal_flow_rate))
                {mass_flow.normal = obj.mass_flow_accuracy_rate+'%'}
              if(parseFloat(obj.flow_rate) === parseFloat(props.max_flow_rate))
                {mass_flow.max=obj.mass_flow_accuracy_rate+'%'}
            }
            data.push(obj);
            obj = {};
            
            
          }
        } catch (e) {
          //No actions  
          console.log(e)
        }



      }
    }else{
      if(search_parameters.select_technology.toLowerCase() === 'coriolis' && props.flow_accuracy===''){
        setFlowAccuracyCheck('');
        setDensityAccuracyCheck('')
        setDensityAccuracy([]);
        setFlowAccuracy([]);
      }

    }

    
    if (
      Object.keys(props.show_more_rates_data).length > 0 &&
      search_parameters.select_technology.toLowerCase() === 'magnetic' &&
      props.show_more_rates_data[0]?props.show_more_rates_data[0].volumetric_acc:false
    ) {
      if(props.flow_accuracy==='' && !resizedAcc) {
        setFlowAccuracyCheck(props.show_more_rates_data[0].volumetric_acc.volumetric_accuracy1)
        props.flowAccuracyValue(props.show_more_rates_data[0].volumetric_acc.volumetric_accuracy1)
      }else if(props.flow_accuracy === '' && props.get_sizing_data.length > 0) {
          setFlowAccuracyCheck(parseFloat(props.get_sizing_data[0].flow_accuracy))
          props.flowAccuracyValue(parseFloat(props.get_sizing_data[0].flow_accuracy))
          props.flowAccuracy(props.get_sizing_data[0].flow_accuracy+'%')
        }
      
      for (let i=0, k = props.show_more_rates_data[0].flow_rates.length-1; k >= 0; k = k - 1) {
        let obj = {};
        obj.flow_rate = props.show_more_rates_data[0].flow_rates[k] !== null? props.show_more_rates_data[0].flow_rates[k].toFixed(flow_rate_decimal):0
        obj.reynolds_nbr=""
        if (props.show_more_rates_data[0].volumetric_flow_accuracy[k] === null) {
          obj.flow_accuracy_rate = 0
        } else if (countDecimalPlaces(props.show_more_rates_data[0].volumetric_flow_accuracy[k]) <= 2 ) {
          obj.flow_accuracy_rate = props.show_more_rates_data[0].volumetric_flow_accuracy[k]
        } else {
          obj.flow_accuracy_rate = props.show_more_rates_data[0].volumetric_flow_accuracy[k].toFixed(all_other_decimal)
        }
        obj.flow_percentage=""
        obj.velocity = props.show_more_rates_data[0].velosity[k] !== null?  props.show_more_rates_data[0].velosity[k].toFixed(all_other_decimal):0
        obj.flow_type=""
        obj.flow_rate_unit= props.show_more_rates_data[0].flow_rate_unit
        obj.velocity_unit=props.show_more_rates_data[0].velosity_unit
        obj.sr_no=i++
        data.push(obj);
        obj = {};
      }
    }
    if (
      Object.keys(props.show_more_rates_data).length > 0 &&
      props.show_more_rates_data[0].display_flow_rate && 
      search_parameters.select_technology.toLowerCase() === 'vortex'
    ) {
      for (let i=0, k =props.show_more_rates_data[0].display_flow_rate.length-1; k >= 0; k = k - 1) {
        let obj = {}; 
        obj.flow_rate = props.show_more_rates_data[0].display_flow_rate[k] !== null?  
        props.show_more_rates_data[0].display_flow_rate[k].toFixed(flow_rate_decimal):0
        if (props.show_more_rates_data[0].volumetric_flow_accuracy[k] === null) {
          obj.flow_accuracy = 0
        } else if (countDecimalPlaces(props.show_more_rates_data[0].volumetric_flow_accuracy[k]) <= 2 ) {
          obj.flow_accuracy = props.show_more_rates_data[0].volumetric_flow_accuracy[k]
        } else {
          obj.flow_accuracy = props.show_more_rates_data[0].volumetric_flow_accuracy[k].toFixed(all_other_decimal)
        }
        obj.pressure_drop = props.show_more_rates_data[0].pressure_drop[k] !== null?  
        props.show_more_rates_data[0].pressure_drop[k].toFixed(line_pressure_decimal):0;
        obj.velocity = props.show_more_rates_data[0].velocity[k].toFixed(all_other_decimal);
        obj.reynolds_number = props.show_more_rates_data[0].reynolds_number[k] !== null?  
        props.show_more_rates_data[0].reynolds_number[k].toFixed(all_other_decimal):0;
        obj.sr_no=i++
        data.push(obj);
        obj = {};
      }
    }
    if (
      Object.keys(props.show_more_rates_data).length > 0  &&
      (search_parameters.select_technology.toLowerCase() === 'density'||
      search_parameters.select_technology.toLowerCase() === 'viscosity')
    ) {
      var filtered =
        (props.fluid_state.toLowerCase() === 'gas' && props.selected_row_data.product !== "GDM")
          ? []
          : props.show_more_rates_data[0].density_accuracy_radio_button.filter(
              function (value, index, arr) {
                return value !== null;
              }
            );
      filtered = filtered.map(val => val.toFixed(all_other_decimal)) 
      if(search_parameters.select_technology.toLowerCase()==='density') {
          if(filtered.length > 1){
            setDensityAccuracy(filtered); 
           }else{ 
            setDensityAccuracy([]);
           }

           if(density_accuracy_check === '' && !resizedAcc){
            props.densityAccuracy(filtered[0])
            setDensityAccuracyCheck(filtered[0])
           }else if(density_accuracy_check !== '' && !resizedAcc){
            props.densityAccuracy(density_accuracy_check)
            setDensityAccuracyCheck(density_accuracy_check)
           }else if(resizedAcc && props.default_row) {
            props.densityAccuracy(props.get_sizing_data[0].den_accuracy_all_rates)
            setDensityAccuracyCheck(props.get_sizing_data[0].den_accuracy_all_rates)
            setResizedAcc(false)
           }
        } 

    if(props.selected_row_data.product !== "GDM"){
      for (let i=0, k = 10; k >= 0; k = k - 1) {
        let obj = {};
        obj.flow_rate = props.show_more_rates_data[0]['display_rate'][k].toFixed(flow_rate_decimal)
        if (props.show_more_rates_data[0]['flow_rate'][k] === null) {
          obj.mass_flow_accuracy_rate = 0
        } else if (countDecimalPlaces(props.show_more_rates_data[0]['flow_rate'][k]) <= 2 ) {
          obj.mass_flow_accuracy_rate = props.show_more_rates_data[0]['flow_rate'][k]
        } else {
          obj.mass_flow_accuracy_rate = props.show_more_rates_data[0]['flow_rate'][k].toFixed(all_other_decimal)
        }
           
        obj.reynolds_nbr = props.show_more_rates_data[0][
          'reynolds_number'
        ].length>0?props.show_more_rates_data[0][
          'reynolds_number'
        ][k].toFixed(all_other_decimal):''
            
        obj.reynolds_number_min = parseFloat(props.show_more_rates_data[0][
          'reynolds_number'
        ]).length>0?parseFloat(props.show_more_rates_data[0][
          'reynolds_number'
        ][k]).toFixed(all_other_decimal):''
            
        obj.reynolds_number_max = parseFloat(props.show_more_rates_data[0][
          'reynolds_number'
        ]).length>0?parseFloat(props.show_more_rates_data[0][
          'reynolds_number'
        ][k]).toFixed(all_other_decimal):'';
        obj.velocity = props.show_more_rates_data[0]['tube_velocity'].length>0?props.show_more_rates_data[0]['tube_velocity'][k] !==null? props.show_more_rates_data[0]['tube_velocity'][k].toFixed(all_other_decimal):0:'';
           
        obj.tube_velocity_max = parseFloat(props.show_more_rates_data[0][
          'tube_velocity'
        ]).length>0?parseFloat(props.show_more_rates_data[0][
          'tube_velocity'
        ][k]).toFixed(all_other_decimal):'';
          
        obj.tube_velocity_min = parseFloat(props.show_more_rates_data[0][
          'tube_velocity'
        ]).length>0?parseFloat(props.show_more_rates_data[0][
          'tube_velocity'
        ][k]).toFixed(all_other_decimal):'';
        obj.pressure_drop = props.show_more_rates_data[0]['pressure_drop'].length>0?
                                props.show_more_rates_data[0]['pressure_drop'][k]!==null?
                                props.show_more_rates_data[0]['pressure_drop'][k]!==0?
                                parseFloat(props.show_more_rates_data[0]['pressure_drop'][k]).toFixed(line_pressure_decimal)
                                :'--':'--':'--'
        obj.sr_no=i++
        data.push(obj);
        obj = {};
      }
    }
    }
    setShowMoreResults(data);
    if(props.show_more_rates_data.length>0&&search_parameters.select_technology.toLowerCase()==='coriolis'){
      props.massFlowAccuracy(mass_flow)
    }
    
    props.showMoreRatesResultData(data)
    let unit  = props.show_more_rates_data[0]?.density_accuracy_units
    unit = unit ? unit : props.units_density_heading.toLowerCase() !== 'density' ? props.units_gas_density_sg.key :  units_density.key
    setDensityAccuracyUnits(unit)
    }
  }, [props.show_more_rates_data, resizedAcc]);



  const radioInputButtons = (id, label,type) => {
    let check = id == flow_accuracy_check ? true : false;
    return (
      <>
        <Col md={12} sm={12} className='Labelpadding'>
          <CustomRadiobutton
            type='radio'
            name='accuracy'
            id={`${type}${id}`}
            onChange={(e) => isChecked(id)}
            checked={check}
            className='custom-radiobutton'
            text={label}
            value={id}
            disabled={false}
          />
        </Col>
      </>
    );
  };

  const disableRadioButton =(val)=>{
    try {
      //flow
      const flow_acc =  props.flow_accuracy_value
      const flow_accuracy_radio_button_array = props.selected_row_data.flow_accuracy_radio_button
      const flow_accuracy_index = flow_accuracy_radio_button_array.indexOf(flow_acc)
      //density
      let density_accuracy_radio_button_array = props.selected_row_data.density_accuracy_radio_button.filter(Number)
      density_accuracy_radio_button_array = density_accuracy_radio_button_array !== undefined && density_accuracy_radio_button_array !== null? density_accuracy_radio_button_array.map(a => a.toFixed(all_other_decimal)):[]
      const density_accuracy_index = density_accuracy_radio_button_array.indexOf(val)
      //accuracy data
      const accuracy_data_row = props.selected_row_data.accuracy_data[flow_accuracy_index]
      const accuracy_data = [accuracy_data_row.densityaccuracy_liquid, accuracy_data_row.densityaccuracysc_liquid, accuracy_data_row.densityaccuracysc2_liquid]

      const currentIndex  = accuracy_data[density_accuracy_index]

      if(currentIndex ===0){
        return true
      }else{
        return false
      }
    } catch (error) {
      return false
    }
  }


  const radioInputDensityButtons = (val, type) => {
      let check = val == density_accuracy_check? true : false;
      // let unit  = props.show_more_rates_data[0]?.density_accuracy_units
      // unit = unit ? unit : props.units_density_heading.toLowerCase() !== 'density' ? props.units_gas_density_sg.key :  units_density.key
    return (
      <>
        <Col md={12} sm={12} className='Labelpadding'>
          <CustomRadiobutton
            type='radio'
            name='coriolis'
            id={`${type}${val}`}
            onChange={(e) => isCheckedDensity(val)}
            checked={check}
            className='custom-radiobutton'
            text={`${val} ${densityAccuracyUnits}`}
            value={val}
            disabled={ type === 'density1'? disableRadioButton(val):false}
          />
        </Col>
      </>
    );
  };

  const renderModelDetails = () => {
    const modelDetail = search_parameters.select_technology.toLowerCase() === 'magnetic' ? `${props.selected_row_data.parent_model+' : '+props.selected_row_data.short_description}` : search_parameters.select_technology.toLowerCase() === 'vortex'  ? `${props.selected_row_data.parent_model+' : '+props.selected_row_data.short_desc}` : `${props.selected_row_data.product+' : '+props.selected_row_data.description}`;
    if(state.productImageUrl.length > 0){
       return state.productImageUrl.map((data) => (
          <p className='showMoreSubHeader'>{data.model_code+' : '+data.description}</p>
        ))
    }else if(props.show_more_rates_data.length > 0){
      return <p className='showMoreSubHeader'>{modelDetail}</p>
    }else {
      return <br/> ;
    }
  }
  
  return (
    <>
    {(imgLoader && props.product_image_api_load) ?
     <Row className="loader">
       <Col xl={12} md={12}>
         <CustomLoader phase={imgLoader} loaderText="innerText" loaderClass="innerLoaderImg" subTitle={CustomTranslationText('XXSPECIFY_MMI_PR_PLS_WAIT', props)} />
       </Col>
     </Row>
     : 
      <div id='show-more-rates-section' className='LineBottom'>
        <h5 className='ShowMoreHeader'>
          <CustomTranslation data='XXSPECIFY_MMI_TOUR_TITLE_7' />
        </h5>
        {renderModelDetails()}
        {search_parameters.select_technology.toLowerCase() === 'coriolis'&&(flow_accuracy.length > 0||density_accuracy.length > 0)? (
        <h5 className='ShowMoreHeader'><CustomTranslation data='XXSPECIFY_MMI_SR_PB_SELACC'/></h5>
        ) : (
          search_parameters.select_technology.toLowerCase() === 'magnetic' ?( <h5 className='ShowMoreHeader'><CustomTranslation data='XXSPECIFY_MMI_SR_PB_SELACC'/></h5>):( <br />)
      
        )}
        <Row>
          <Col md='4' xl='4'>
            {search_parameters.select_technology.toLowerCase() === 'coriolis'&& props.show_more_rates_data.length > 0&&(flow_accuracy.length > 0||density_accuracy.length > 0)  ? (
              <Row className='showMoreBorder'>
                <Col xl='6' md='6' className='showMorePadding'>
                  <label><CustomTranslation data='XXSPECIFY_MMI_SR_LBL_FLOAC'/></label>
                  {flow_accuracy.length > 0 ? (
                    flow_accuracy.map((data) =>
                      radioInputButtons(data, `${data}%`,'flow')
                    )
                  ) : (
                    <br />
                  )}
                </Col>
                <Col xl='6' md='6' className='showMorePadding'>
                  <label><CustomTranslation data='XXSPECIFY_MMI_SR_LBL_DENS'/></label>
                  {density_accuracy.length > 0 ? (
                    density_accuracy.map((data) =>
                    radioInputDensityButtons(data, 'density1')
                    )
                  ) : (
                    <br />
                  )}
                </Col>
              </Row>
            ) : (
              ''
            )}
            {props.show_more_rates_data.length>0?props.show_more_rates_data[0].volumetric_acc ? (
              <Row className='showMoreBorder'>
                <Col xl='6' md='6' className='showMorePadding'>
                  <label><CustomTranslation data='XXSPECIFY_MMI_SR_PB_SELVELACC'/></label>
                  {Object.keys(props.show_more_rates_data).length > 0 ? (
                    radioInputButtons(
                      props.show_more_rates_data[0].volumetric_acc.volumetric_accuracy1,
                      `${props.show_more_rates_data[0].volumetric_acc.volumetric_accuracy1}%`,
                      'volume1'
                    )
                    
                  ) : (
                    <br />
                  )}
                  {Object.keys(props.show_more_rates_data).length > 0 ? (
                    radioInputButtons(
                      props.show_more_rates_data[0].volumetric_acc.volumetric_accuracy2,
                      `${props.show_more_rates_data[0].volumetric_acc.volumetric_accuracy2}%`,
                      'volume2'
                    )
                    
                  ) : (
                    <br />
                  )}
                </Col>
              </Row>
            ) : (
              ''
            ):""}
            {search_parameters.select_technology.toLowerCase() === 'density'&& props.show_more_rates_data.length > 0&&(density_accuracy.length > 0)  ? (
              <Row className='showMoreBorder'>
            
                <Col xl='6' md='6' className='showMorePadding'>
                  <label><CustomTranslation data='XXSPECIFY_MMI_SR_LBL_DENS'/></label>
                  {density_accuracy.length > 0 ? (
                    density_accuracy.map((data) =>
                    radioInputDensityButtons(data, 'density2')
                    )
                  ) : (
                    <br />
                  )}
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Col>
          <Col md='8' xl='8'>
            {state.productImageUrl.length > 0 ? (
              state.productImageUrl.map((data) => (
                <>
                  {props.product_image_url_load ? (
                    <img
                      className='imageRight'
                      src={loader}
                      alt='Loader'
                      height='60'
                      width='60'
                    />
                  ) : (
                    <img
                      className='imageRight'
                      src={
                        data.image_data !== ''
                          ? `${BASE64_IMG_PREFIX + data.image_data}`
                          : pictureComingSoon
                      }
                      alt={data.model_code}
                      height={'220'}
                      width={'220'}              
                    />
                  )}
                </>
              ))
            ) : (
              // TBD <img className="imageRight" src={loader} alt="Loader" height="60" width="60" />
              <img
                className='imageRight'
                src={pictureComingSoon}
                alt='Loader'
                height='60'
                width='60'
              />
            )}
          </Col>
          <Col>
            {showMoreRatesTableData}
          </Col>
        </Row>
      </div>
    }
  </> 
  );
};

const mapStateToProps = (state) => ({
  product_image_url: state.SizingResults.product_image_url,
  product_image_url_load: state.SizingResults.product_image_url_load,
  product_image_api_load: state.SizingResults.product_image_api_load,
  show_more_rates_data: state.SizingResults.show_more_rates_data,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  units_gas_flow_rate: state.ProcessVariables.units_gas_flow_rate,
  select_technology: state.MeasurementType.select_technology,
  search_parameters: state.SizingResults.search_parameters,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  fluid_state: state.FluidSelection.fluid_state,
  calculate_density_variation:
    state.ProcessVariables.calculate_density_variation,
  was_clicked: state.SizingResults.was_clicked,
  is_applied: state.SizingResults.is_applied,
  selected_technology: state.ProcessVariables.selected_technology,
  flow_rate_decimal: state.Preferences.flow_rate_decimal,
  temperature_decimal: state.Preferences.temperature_decimal,
  line_pressure_decimal: state.Preferences.line_pressure_decimal,
  all_other_decimal: state.Preferences.all_other_decimal,
  units_data: state.DropdownData.units_data,
  translation_data: state.TranslationData.translation_data,
  flow_accuracy: state.SizingResults.flow_accuracy,
  density_accuracy: state.SizingResults.density_accuracy,
  flow_accuracy_value: state.SizingResults.flow_accuracy_value,
  units_line_pressure: state.ProcessVariables.units_line_pressure,
  units_density: state.ProcessVariables.units_density,
  selected_row_data: state.SizingResults.selected_row_data,
  get_sizing_data: state.MySizings.get_sizing_data,
  default_row: state.SizingResults.default_row,
  units_density_heading: state.ProcessVariables.units_density_heading,
  units_gas_density: state.ProcessVariables.units_gas_density,
  units_gas_density_sg: state.ProcessVariables.units_gas_density_sg,
});

export default connect(mapStateToProps, {
  productImageUrlLoad,
  showMoreRatesResultData,
  flowAccuracy,
  densityAccuracy,
  massFlowAccuracy,
  flowAccuracyValue
})(ShowMoreRates);