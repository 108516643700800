export const ADVANCED_LAYOUT = "ADVANCED_LAYOUT";
export const RESET_APP = "RESET_APP";
export const WAS_CLICKED = "WAS_CLICKED";
export const IS_APPLIED = "IS_APPLIED";
export const SEARCH_PARAMETERS = "SEARCH_PARAMETERS";
export const RESET_OPERATING_CONDITIONS = "RESET_OPERATING_CONDITIONS";
export const SELECTED_TECHNOLOGY = "SELECTED_TECHNOLOGY";
export const SELECT_BASIC_PAGE = "SELECT_BASIC_PAGE";
export const TOGGLE_FLOW = "TOGGLE_FLOW";
export const TOGGLE_DENSITY = "TOGGLE_DENSITY";
export const TOGGLE_VISCOSITY = "TOGGLE_VISCOSITY";
export const SELECT_TECHNOLOGY = "SELECT_TECHNOLOGY";
export const DEFAULT_TECHNOLOGY = "DEFAULT_TECHNOLOGY";
export const PREVIOUS_TECHNOLOGY = "PREVIOUS_TECHNOLOGY";
export const OPERATING_CONDITIONS = "OPERATING_CONDITIONS";
export const DISABLE_SIZING_RESULTS_BUTTON = "DISABLE_SIZING_RESULTS_BUTTON";
export const CONVERT_DISABLE_BUTTON = "CONVERT_DISABLE_BUTTON";
export const EQUIPMENT_SELECTION = "EQUIPMENT_SELECTION";
export const TOGGLE_HYGIENIC = "TOGGLE_HYGIENIC";
export const QUERY_PARAMS = "QUERY_PARAMS";
export const OPERATING_DATA_ERROR_MESSAGES = "OPERATING_DATA_ERROR_MESSAGES";
export const TOGGLE_DISPLAY_ALL_SENSORS_NO_FILTERS = "TOGGLE_TOGGLE_DISPLAY_ALL_SENSORS_NO_FILTERS";
export const TOGGLE_IN_LINE = "TOGGLE_IN_LINE";
export const TOGGLE_FLOW_THROUGH_CHAMBER_SLIPSTREAM = "TOGGLE_FLOW_THROUGH_CHAMBER_SLIPSTREAM";
export const TOGGLE_TANK = "TOGGLE_TANK";
export const TOGGLE_HYGIENIC_APPLICATION = "TOGGLE_HYGIENIC_APPLICATION";
export const TOGGLE_HIGH_PROCESS_NOISE_SLURRY = "TOGGLE_HIGH_PROCESS_NOISE_SLURRY";
export const PRODUCT_FAMILY = "PRODUCT_FAMILY";
export const PRODUCT_FAMILY_NAME = "PRODUCT_FAMILY_NAME";
export const PRODUCT_FAMILY_UNITS = "PRODUCT_FAMILY_UNITS";
export const LINE_SIZE = "LINE_SIZE";
export const LINE_SIZE_LABEL = "LINE_SIZE_LABEL";
export const LINE_SIZE_ERROR = "LINE_SIZE_ERROR";
export const LINE_SIZE_UNITS = "LINE_SIZE_UNITS";
export const PROCESS_LINE_SIZE = "PROCESS_LINE_SIZE";
export const PROCESS_LINE_SIZE_LABEL = "PROCESS_LINE_SIZE_LABEL";
export const PROCESS_LINE_SIZE_UNITS = "PROCESS_LINE_SIZE_UNITS";
export const SCHEDULE = "SCHEDULE";
export const TRIGGER_LEVEL = "TRIGGER_LEVEL";
export const TOGGLE_FLANGED = "TOGGLE_FLANGED";
export const TOGGLE_WAFER = "TOGGLE_WAFER";
export const TOGGLE_REDUCER = "TOGGLE_REDUCER";
export const TOGGLE_DUAL = "TOGGLE_DUAL";
export const TOGGLE_QUAD = "TOGGLE_QUAD";
export const TOOGLE_PRESSURE = "TOOGLE_PRESSURE";
export const PIPE_ID = "PIPE_ID";
export const PIPE_ID_UNITS = "PIPE_ID_UNITS";
export const FLUID_STATE = "FLUID_STATE";
export const FLUID_STATE_UNITS = "FLUID_STATE_UNITS";
export const FLUID_SOURCE = "FLUID_SOURCE";
export const REVERT_FLAG = "REVERT_FLAG";
export const COOLPROPS_VISC = "COOLPROPS_VISC";
export const FLUID_SOURCE_UNITS = "FLUID_SOURCE_UNITS";
export const FLUID_SELECTION_FLAG = "FLUID_SELECTION_FLAG";
export const COMPOSITION_METHOD_FLAG = "COMPOSITION_METHOD_FLAG";
export const FLUID_SELECTION_SECTION = "FLUID_SELECTION_SECTION";
export const COMPOSITION_METHOD_SECTION = "COMPOSITION_METHOD_SECTION";
export const PICK_FLUIDS_FROM_DB = "PICK_FLUIDS_FROM_DB";
export const PICK_FLUIDS_COOLPROPS_FLAG = "PICK_FLUIDS_COOLPROPS_FLAG";
export const PICK_FLUIDS_FROM_DB_UNITS = "PICK_FLUIDS_FROM_DB_UNITS";
export const PICK_GAS_FROM_DB = "PICK_GAS_FROM_DB";
export const CONDUCTIVITY = "CONDUCTIVITY";
export const REPORT_CONFIGS = "REPORT_CONFIGS";
export const CONDUCTIVITY_UNITS = "CONDUCTIVITY_UNITS";
export const FLUID_SERVICE = "FLUID_SERVICE";
export const FLUID_SERVICE_KEY = "FLUID_SERVICE_KEY";
export const FLUID_SERVICE_UNITS = "FLUID_SERVICE_UNITS";
export const CUSTOM_FLUID_NAME = "CUSTOM_FLUID_NAME";
export const STEAM_PRESSURE_TEMP = "STEAM_PRESSURE_TEMP";
export const NATURAL_GAS_COMPOSE = "NATURAL_GAS_COMPOSE";
export const NATURAL_GAS_NAME_BORDER_ERROR = "NATURAL_GAS_NAME_BORDER_ERROR";
export const NATURAL_GAS_COMPOSITION_NAME = "NATURAL_GAS_COMPOSITION_NAME";
export const COMPOSITION_METHOD = "COMPOSITION_METHOD";
export const DATABASE_GASSES = "DATABASE_GASSES";
export const RELATIVE_DENSITY = "RELATIVE_DENSITY";
export const MOLE_PERCENT_CO2 = "MOLE_PERCENT_CO2";
export const HEATING_VALUE = "HEATING_VALUE";
export const HEATING_VALUE_DROPDOWN = "HEATING_VALUE_DROPDOWN";
export const MOLE_PERCENT_H2 = "MOLE_PERCENT_H2";
export const MOLE_PERCENT_CO = "MOLE_PERCENT_CO";
export const MOLE_ARRAY_REDUX = "MOLE_ARRAY_REDUX";
export const SELECTED_DATABASE_NATURAL_GAS = "SELECTED_DATABASE_NATURAL_GAS";
export const AVAILABLE_COMPONENTS = "AVAILABLE_COMPONENTS";
export const SELECTED_COMPONENTS = "SELECTED_COMPONENTS";
export const UNITS_FLOW_RATE = "UNITS_FLOW_RATE";
export const UNITS_GAS_FLOW_RATE = "UNITS_GAS_FLOW_RATE";
export const MIN_FLOW_RATE = "MIN_FLOW_RATE";
export const MIN_FLOW_RATE_VALUE = "MIN_FLOW_RATE_VALUE";
export const NORMAL_FLOW_RATE = "NORMAL_FLOW_RATE";
export const MAX_FLOW_RATE = "MAX_FLOW_RATE";
export const FULL_FLOW_RATE = "FULL_FLOW_RATE";
export const UNITS_LINE_PRESSURE = "UNITS_LINE_PRESSURE";
export const MIN_LINE_PRESSURE = "MIN_LINE_PRESSURE";
export const MIN_LINE_PRESSURE_VALUE = "MIN_LINE_PRESSURE_VALUE";
export const NORMAL_LINE_PRESSURE = "NORMAL_LINE_PRESSURE";
export const NORMAL_LINE_PRESSURE_VALUE = "NORMAL_LINE_PRESSURE_VALUE";
export const MAX_LINE_PRESSURE = "MAX_LINE_PRESSURE";
export const MAX_LINE_PRESSURE_VALUE = "MAX_LINE_PRESSURE_VALUE";
export const FULL_LINE_PRESSURE = "FULL_LINE_PRESSURE";
export const FULL_LINE_PRESSURE_VALUE = "FULL_LINE_PRESSURE_VALUE";
export const UNITS_TEMPERATURE = "UNITS_TEMPERATURE";
export const MIN_TEMPERATURE = "MIN_TEMPERATURE";
export const MIN_TEMPERATURE_VALUE = "MIN_TEMPERATURE_VALUE";
export const NORMAL_TEMPERATURE = "NORMAL_TEMPERATURE";
export const NORMAL_TEMPERATURE_VALUE = "NORMAL_TEMPERATURE_VALUE";
export const MAX_TEMPERATURE = "MAX_TEMPERATURE";
export const MAX_TEMPERATURE_VALUE = "MAX_TEMPERATURE_VALUE";
export const FULL_TEMPERATURE = "FULL_TEMPERATURE";
export const FULL_TEMPERATURE_VALUE = "FULL_TEMPERATURE_VALUE";
export const UNITS_AMBIENT = "UNITS_AMBIENT";
export const MIN_AMBIENT = "MIN_AMBIENT";
export const NORMAL_AMBIENT = "NORMAL_AMBIENT";
export const MAX_AMBIENT = "MAX_AMBIENT";
export const FULL_AMBIENT = "FULL_AMBIENT";
export const UNITS_DENSITY = "UNITS_DENSITY";
export const MIN_DENSITY = "MIN_DENSITY";
export const MIN_DENSITY_VALUE = "MIN_DENSITY_VALUE";
export const NORMAL_DENSITY = "NORMAL_DENSITY";
export const NORMAL_DENSITY_VALUE = "NORMAL_DENSITY_VALUE";
export const DENSITY_SG = "DENSITY_SG";
export const DENSITY_SG_VALUE = "DENSITY_SG_VALUE";
export const GAS_DENSITY_ERROR = "GAS_DENSITY_ERROR";
export const MINIMUM_DENSITY_ERROR = "MINIMUM_DENSITY_ERROR";
export const NORMAL_DENSITY_ERROR = "NORMAL_DENSITY_ERROR";
export const MAXIMUM_DENSITY_ERROR = "MAXIMUM_DENSITY_ERROR";
export const NORMAL_DENSITY_MIN_MAX_ERRORS = "NORMAL_DENSITY_MIN_MAX_ERRORS";
export const MAX_DENSITY_MIN_MAX_ERRORS = "MAX_DENSITY_MIN_MAX_ERRORS";
export const NORMAL_DENSITY_TYPE_ERRRORS = "NORMAL_DENSITY_TYPE_ERRRORS";
export const MAX_DENSITY_TYPE_ERRORS = "MAX_DENSITY_TYPE_ERRORS";
export const NORMAL_VISCOSITY_ERROR = "NORMAL_VISCOSITY_ERROR";
export const MIN_VISCOSITY_CONVERTION_ERROR = "MIN_VISCOSITY_CONVERTION_ERROR";
export const NORMAL_VISCOSITY_CONVERTION_ERROR = "NORMAL_VISCOSITY_CONVERTION_ERROR";
export const MAX_VISCOSITY_CONVERTION_ERROR = "MAX_VISCOSITY_CONVERTION_ERROR";
export const MAXIMUM_VISCOSITY_ERROR = "MAXIMUM_VISCOSITY_ERROR";
export const NORMAL_VISCOSITY_MIN_MAX_ERRORS = "NORMAL_VISCOSITY_MIN_MAX_ERRORS";
export const MAX_VISCOSITY_MIN_MAX_ERRORS = "MAX_VISCOSITY_MIN_MAX_ERRORS";
export const NORMAL_VISCOSITY_TYPE_ERRORS = "NORMAL_VISCOSITY_TYPE_ERRORS";
export const MAX_VISCOSITY_TYPE_ERRORS = "MAX_VISCOSITY_TYPE_ERRORS";
export const MAX_DENSITY = "MAX_DENSITY";
export const MAX_DENSITY_VALUE = "MAX_DENSITY_VALUE";
export const UNITS_VISCOSITY = "UNITS_VISCOSITY";
export const MIN_VISCOSITY = "MIN_VISCOSITY";
export const MIN_VISCOSITY_VALUE = "MIN_VISCOSITY_VALUE";
export const NORMAL_VISCOSITY = "NORMAL_VISCOSITY";
export const NORMAL_VISCOSITY_VALUE = "NORMAL_VISCOSITY_VALUE";
export const MAX_VISCOSITY = "MAX_VISCOSITY";
export const MAX_VISCOSITY_VALUE = "MAX_VISCOSITY_VALUE";
export const UNITS_VAPOR = "UNITS_VAPOR";
export const IS_SELECTED = " IS_SELECTED";
export const MIN_VAPOR = "MIN_VAPOR";
export const MIN_VAPOR_VALUE = "MIN_VAPOR_VALUE";
export const MAX_FLOW_ACC = "MAX_FLOW_ACC";
export const UNITS_MAX_PRESSURE_DROP = "UNITS_MAX_PRESSURE_DROP";
export const MAX_PRESSURE_DROP = "MAX_PRESSURE_DROP";
export const UNITS_SONIC_VELOCITY = "UNITS_SONIC_VELOCITY";
export const SONIC_VELOCITY = "SONIC_VELOCITY";
export const SONIC_VELOCITY_MAP = "SONIC_VELOCITY_MAP";
export const SONIC_VELOCITY_VALUE = "SONIC_VELOCITY_VALUE";
export const UNITS_GAS_PRESSURE = "UNITS_GAS_PRESSURE";
export const GAS_PRESSURE = "GAS_PRESSURE";
export const GAS_PRESSURE_ERROR = "GAS_PRESSURE_ERROR";
export const UNITS_GAS_TEMPERATURE = "UNITS_GAS_TEMPERATURE";
export const GAS_TEMPERATURE = "GAS_TEMPERATURE";
export const GAS_TEMPERATURE_ERROR = "GAS_TEMPERATURE_ERROR";
export const UNITS_GAS_DENSITY = "UNITS_GAS_DENSITY";
export const RESIZING_DISCARD_FLAG = "RESIZING_DISCARD_FLAG";
export const ALLOW_PREF_BASE_REF = "ALLOW_PREF_BASE_REF";
export const UNITS_GAS_DENSITY_SG = "UNITS_GAS_DENSITY_SG";
export const SAVED_PREFERENCES_FLAG = "SAVED_PREFERENCES_FLAG";
export const GAS_DENSITY = "GAS_DENSITY";
export const GAS_DENSITY_VALUE = "GAS_DENSITY_VALUE";
export const RESET_RESIZING_FLAG = "RESET_RESIZING_FLAG";
export const UNITS_PRESSURE_ATMOSPHERIC = "UNITS_PRESSURE_ATMOSPHERIC";
export const PRESSURE_ATMOSPHERIC = "PRESSURE_ATMOSPHERIC";
export const UNITS_MINIMUM_ACCURACY = "UNITS_MINIMUM_ACCURACY";
export const UNITS_DENSITY_HEADING = "UNITS_DENSITY_HEADING";
export const NON_NEWTONIAN_LIQUID = "NON_NEWTONIAN_LIQUID";
export const NON_NEWTONIAN_LIQUID_FLAG = "NON_NEWTONIAN_LIQUID_FLAG";
export const REVERT_NON_NEWTONIAN_CHECKBOX_FLAG = "REVERT_NON_NEWTONIAN_CHECKBOX_FLAG";
export const CALCULATE_DENSITY_VARIATION = "CALCULATE_DENSITY_VARIATION";
export const K_FLOW_CONSISTENCY_INDEX = "K_FLOW_CONSISTENCY_INDEX";
export const N_FLOW_BEHAVIOR = "N_FLOW_BEHAVIOR";
export const COMPRESSIBILITY = "COMPRESSIBILITY";
export const BASE_COMPRESSIBILITY = "BASE_COMPRESSIBILITY";
export const BASE_COMPRESSIBILITY_ERROR = "BASE_COMPRESSIBILITY_ERROR";
export const GAS_CUSTOM_COMPRESSIBILITY_ERROR = "GAS_CUSTOM_COMPRESSIBILITY_ERROR";
export const SPECIFIC_GRAVITY = "SPECIFIC_GRAVITY";
export const SPECIFIC_GRAVITY_ERROR = "SPECIFIC_GRAVITY_ERROR";
export const MOLECULAR_WEIGHT = "MOLECULAR_WEIGHT";
export const MOLECULAR_WEIGHT_ERROR = "MOLECULAR_WEIGHT_ERROR";
export const BASE_REFERENCE_CONDITIONS = "BASE_REFERENCE_CONDITIONS";
export const APPLICATION = "APPLICATION";
export const SERVICE = "SERVICE";
export const SENSOR_TAG = "SENSOR_TAG";
export const TRANSMITTER_TAG = "TRANSMITTER_TAG";
export const PROJECT_NAME = "PROJECT_NAME";
export const FLOW_RATE_UNIT_FOR_LIQUID_SLURRY_PREF = "FLOW_RATE_UNIT_FOR_LIQUID_SLURRY_PREF";
export const FLOW_RATE_UNIT_FOR_GAS_PREF = "FLOW_RATE_UNIT_FOR_GAS_PREF";
export const TEMPERATURE_UNIT_PREF = "TEMPERATURE_UNIT_PREF";
export const AMBIENT_TEMPERATURE_UNIT_PREF = "AMBIENT_TEMPERATURE_UNIT_PREF";
export const LINE_PRESSURE_UNIT_PREF = "LINE_PRESSURE_UNIT_PREF";
export const DENSITY_UNIT_PREF = "DENSITY_UNIT_PREF";
export const VISCOSITY_UNIT_PREF = "VISCOSITY_UNIT_PREF";
export const VAPOR_PRESSURE_UNIT_PREF = "VAPOR_PRESSURE_UNIT_PREF";
export const ATMOSPHERIC_UNIT_PREF = "ATMOSPHERIC_UNIT_PREF";
export const GAS_ONLY_PRESSURE_UNIT_PREF = "GAS_ONLY_PRESSURE_UNIT_PREF";
export const GAS_ONLY_PRESSURE_PREF = "GAS_ONLY_PRESSURE_PREF";
export const GAS_ONLY_TEMPERATURE_UNIT_PREF = "GAS_ONLY_TEMPERATURE_UNIT_PREF";
export const GAS_ONLY_TEMPERATURE_PREF = "GAS_ONLY_TEMPERATURE_PREF";
export const GAS_ONLY_DENSITY_UNIT_PREF = "GAS_ONLY_DENSITY_UNIT_PREF";
export const SONIC_VELOCITY_UNIT_PREF = "SONIC_VELOCITY_UNIT_PREF";
export const FLOW_RATE_DECIMAL_FACTOR = "FLOW_RATE_DECIMAL_FACTOR";
export const FLOW_RATE_DECIMAL = "FLOW_RATE_DECIMAL";
export const TEMPERATURE_DECIMAL_FACTOR = "TEMPERATURE_DECIMAL_FACTOR";
export const TEMPERATURE_DECIMAL = "TEMPERATURE_DECIMAL";
export const LINE_PRESSURE_DECIMAL_FACTOR = "LINE_PRESSURE_DECIMAL_FACTOR";
export const LINE_PRESSURE_DECIMAL = "LINE_PRESSURE_DECIMAL";
export const DECIMAL_FACTOR_PREF = "DECIMAL_FACTOR_PREF";
export const ALL_OTHER_DECIMAL = "ALL_OTHER_DECIMAL";
export const UOM_AUTO_CONVERSION_PREF = "UOM_AUTO_CONVERSION_PREF";
export const REFRESH_PREF = "REFRESH_PREF";
export const WETTED_MATERIAL_PREF = "WETTED_MATERIAL_PREF";
export const RESIZING_FLAG = "RESIZING_FLAG";
export const FLUID_SOURCE_ARRAY = "FLUID_SOURCE_ARRAY";
export const FLUID_STATE_ARRAY = "FLUID_STATE_ARRAY";
export const REQUEST_SIZING_RESULTS = "REQUEST_SIZING_RESULTS";
export const DISPLAY_SIZING_RESULTS = "DISPLAY_SIZING_RESULTS";
export const COMPARE_SIZING_RESULTS = "COMPARE_SIZING_RESULTS";
export const COMPARE_DISABLE_BUTTON = "COMPARE_DISABLE_BUTTON";
export const CONFIG_WARNING_MSG = "CONFIG_WARNING_MSG";
export const SONIC_VELOCITY_WARNING_MSG = "SONIC_VELOCITY_WARNING_MSG";
export const SONIC_VELOCITY_ENTERED = "SONIC_VELOCITY_ENTERED";
export const AMBIENT_TEMP_WARNING_MSG = "AMBIENT_TEMP_WARNING_MSG"; 
export const HIGH_TEMP_WARNING_MSG = "HIGH_TEMP_WARNING_MSG";
export const HIGH_TEMPERATURE_ERROR_MSG = "HIGH_TEMPERATURE_ERROR_MSG";
export const SIZING_RESULTS_DATA = "SIZING_RESULTS_DATA";
export const LANGUAGE_LIST = "LANGUAGE_LIST";
export const LOGIN_EMAIL = "LOGIN_EMAIL";
export const SAVED_PREFERENCES = "SAVED_PREFERENCES";
export const UNITS_DATA = "UNITS_DATA";
export const CLEAR_MY_SIZINGS = "CLEAR_MY_SIZINGS";
export const FETCH_DROPDOWN_DATA = "FETCH_DROPDOWN_DATA";
export const TRANSLATION_DATA = "TRANSLATION_DATA";
export const USER_LANGUAGE = "USER_LANGUAGE";
export const CLEAR_UOM = "CLEAR_UOM";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const SAVED_SIZING_LOAD = "SAVED_SIZING_LOAD";
export const SCHEDULE_UNITS = "SCHEDULE_UNITS";
export const NATURAL_GAS_COMPONENTS = "NATURAL_GAS_COMPONENTS";
export const SIZING_ERROR_MESSAGE = "SIZING_ERROR_MESSAGE";
export const INTERNET_MODE = "INTERNET_MODE";
export const TRANSLATION_ERROR = "TRANSLATION_ERROR";
export const CLEAR_ALL_FIELD = "CLEAR_ALL_FIELD";
export const CLEAR_APP_REQUIREMENTS = "CLEAR_APP_REQUIREMENTS";
export const CLEAR_EQ_SELECTION = "CLEAR_EQ_SELECTION";
export const CLEAR_MEAS_TYPE = "CLEAR_MEAS_TYPE";
export const CLEAR_FLUID_SELECTION = "CLEAR_FLUID_SELECTION";
export const CLEAR_PROC_VARIABLES = "CLEAR_PROC_VARIABLES";
export const CLEAR_PREF_VARIABLES = "CLEAR_PREF_VARIABLES";
export const CLEAR_PROC_VARIABLES_VALUES = "CLEAR_PROC_VARIABLES_VALUES";
export const PRODUCT_IMAGE_URL = "PRODUCT_IMAGE_URL";
export const SHOW_MORE_RATES_LOADER = "SHOW_MORE_RATES_LOADER";
export const PRODUCT_IMAGE_URL_LOAD = "PRODUCT_IMAGE_URL_LOAD";
export const PRODUCT_IMAGE_API_LOAD = "PRODUCT_IMAGE_API_LOAD";
export const PROCEED_LOAD = "PROCEED_LOAD";
export const GET_SIZING_DATA = "GET_SIZING_DATA";
export const MY_SIZING_RESULTS = "MY_SIZING_RESULTS";
export const GET_SIZING_FORMATTED_DATA = "GET_SIZING_FORMATTED_DATA";
export const COMPARE_MODEL_NAMES = "COMPARE_MODEL_NAMES";
export const TUBE_VELOCITY_UNIT = "TUBE_VELOCITY_UNIT";
export const SHOW_MORE_RATES_DATA = "SHOW_MORE_RATES_DATA";
export const MOLE_PERCENT_N2 = "MOLE_PERCENT_N2";
export const DATABASE_GASSES_LIST = "DATABASE_GASSES_LIST";
export const APPLICATION_NAME_ERROR = "APPLICATION_NAME_ERROR";
export const REPORT_FLAG = "REPORT_FLAG";
export const SELECTED_ROW_DATA = "SELECTED_ROW_DATA";
export const SET_DEFAULT_ROW = "SET_DEFAULT_ROW";
export const SHOW_MORE_RATES_RESULT_DATA = " SHOW_MORE_RATES_RESULT_DATA";
export const OPERATING_DATA = "OPERATING_DATA";
export const FLOW_RATE_MIN_CLASS_LIST = "FLOW_RATE_MIN_CLASS_LIST";
export const FLOW_RATE_NORM_CLASS_LIST = "FLOW_RATE_NORM_CLASS_LIST";
export const FLOW_RATE_MAX_CLASS_LIST = "FLOW_RATE_MAX_CLASS_LIST";
export const FLOW_RATE_FULL_CLASS_LIST = "FLOW_RATE_FULL_CLASS_LIST";
export const FLOW_RATE_MIN_ERRORS = "FLOW_RATE_MIN_ERRORS";
export const FLOW_RATE_NORM_ERRORS = "FLOW_RATE_NORM_ERRORS";
export const FLOW_RATE_MAX_ERRORS = "FLOW_RATE_MAX_ERRORS";
export const FLOW_RATE_FULL_ERRORS = "FLOW_RATE_FULL_ERRORS";
export const FLOW_RATE_DROPDOWN_ARRAY = "FLOW_RATE_DROPDOWN_ARRAY";
export const FLOW_RATE_GAS_DROPDOWN_ARRAY = "FLOW_RATE_GAS_DROPDOWN_ARRAY";
export const LINE_PRESS_MIN_CLASS_LIST = "LINE_PRESS_MIN_CLASS_LIST";
export const LINE_PRESS_NORM_CLASS_LIST = "LINE_PRESS_NORM_CLASS_LIST";
export const LINE_PRESS_MAX_CLASS_LIST = "LINE_PRESS_MAX_CLASS_LIST";
export const LINE_PRESS_FULL_CLASS_LIST = "LINE_PRESS_FULL_CLASS_LIST";
export const LINE_PRESS_MIN_ERRORS = "LINE_PRESS_MIN_ERRORS";
export const LINE_PRESS_NORM_ERRORS = "LINE_PRESS_NORM_ERRORS";
export const LINE_PRESS_MAX_ERRORS = "LINE_PRESS_MAX_ERRORS";
export const LINE_PRESS_FULL_ERRORS = "LINE_PRESS_FULL_ERRORS";
export const LINE_PRESS_DROPDOWN_ARRAY = "LINE_PRESS_DROPDOWN_ARRAY";
export const TEMPERATURE_MIN_CLASS_LIST = "TEMPERATURE_MIN_CLASS_LIST";
export const TEMPERATURE_NORM_CLASS_LIST = "TEMPERATURE_NORM_CLASS_LIST";
export const TEMPERATURE_MAX_CLASS_LIST = "TEMPERATURE_MAX_CLASS_LIST";
export const TEMPERATURE_FULL_CLASS_LIST = "TEMPERATURE_FULL_CLASS_LIST";
export const TEMPERATURE_MIN_ERRORS = "TEMPERATURE_MIN_ERRORS";
export const TEMPERATURE_NORM_ERRORS = "TEMPERATURE_NORM_ERRORS";
export const TEMPERATURE_MAX_ERRORS = "TEMPERATURE_MAX_ERRORS";
export const TEMPERATURE_FULL_ERRORS = "TEMPERATURE_FULL_ERRORS";
export const TEMPERATURE_DROPDOWN_ARRAY = "TEMPERATURE_DROPDOWN_ARRAY";
export const AMBIENT_TEMPERATURE_MIN_CLASS_LIST = "AMBIENT_TEMPERATURE_MIN_CLASS_LIST";
export const AMBIENT_TEMPERATURE_NORM_CLASS_LIST = "AMBIENT_TEMPERATURE_NORM_CLASS_LIST";
export const AMBIENT_TEMPERATURE_MAX_CLASS_LIST = "AMBIENT_TEMPERATURE_MAX_CLASS_LIST";
export const AMBIENT_TEMPERATURE_FULL_CLASS_LIST = "AMBIENT_TEMPERATURE_FULL_CLASS_LIST";
export const AMBIENT_TEMPERATURE_MIN_ERRORS = "AMBIENT_TEMPERATURE_MIN_ERRORS";
export const AMBIENT_TEMPERATURE_NORM_ERRORS = "AMBIENT_TEMPERATURE_NORM_ERRORS";
export const AMBIENT_TEMPERATURE_MAX_ERRORS = "AMBIENT_TEMPERATURE_MAX_ERRORS";
export const AMBIENT_TEMPERATURE_FULL_ERRORS = "AMBIENT_TEMPERATURE_FULL_ERRORS";
export const AMBIENT_TEMPERATURE_DROPDOWN_ARRAY = "AMBIENT_TEMPERATURE_DROPDOWN_ARRAY";
export const CUSTOM_ERRORS = "CUSTOM_ERRORS";
export const CUSTOM_BORDER_ERROR = "CUSTOM_BORDER_ERROR";
export const NAVBAR_FLAG = "NAVBAR_FLAG";
export const REQUIRED_FIELDS = "REQUIRED_FIELDS";
export const SET_USERINFO = "SET_USERINFO";
export const FLOW_ACCURACY = "FLOW_ACCURACY";
export const FLOW_ACCURACY_VALUE = "FLOW_ACCURACY_VALUE";
export const DENSITY_ACCURACY = "DENSITY ACCURACY";
export const MASS_FLOW = "MASS_FLOW";
export const WETTED_MATERIAL = "WETTED_MATERIAL";
export const WETTED_MATERIAL_LABEL  = "WETTED_MATERIAL_LABEL";
export const WETTED_MATERIAL_VALUE = "WETTED_MATERIAL_VALUE";
export const WETTED_MATERIAL_UNITS = "WETTED_MATERIAL_UNITS";
export const LINE_PRESS_ROW_PSGI = "LINE_PRESS_ROW_PSGI";
export const SET_CALC_FLAG = "SET_CALC_FLAG";
export const SIZING_RESULTS_CLICKED = "SIZING_RESULTS_CLICKED";
export const TEMP_ROW_F = "TEMP_ROW_F";
export const WARNING_LIMIT = "WARNING_LIMIT";
export const SIS_APPLICATION = "SIS_APPLICATION";
export const TOTAL_MOLE = "TOTAL_MOLE";
export const MEASUREMENT_TYPE_CONTAINER_CLASS = "MEASUREMENT_TYPE_CONTAINER_CLASS";
